import React, { useEffect, useContext, useState} from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Grid } from "@mui/material";
import styles from "./ScreenPresPrep.module.css";
import { server } from "../settings";
import {getDataJson , getDataJsonWoOldval} from "../common/getDataJson.js";

export default function ScreenPresPrep({statem}) {
  const [expanded, setExpanded] = useState([]);
  // const [statem, setStatem] = useState("");
  const [statemWait, setStatemWait] = useState([]);
  useEffect(() => {
    // setStatemWait(["a","b","c"]);
  },[])

  function UpdateWaitList(json) {
    let list = [];
    for (const element of json) {
      console.log("UpdateWaitList",element["LastName"]);
      list.push(element["LastName"]+ " "+element["FirstName"]+" "+element["SecondName"])
    }
    setStatemWait(list);
    // getDataJson(server.api+"/delegates.php?statem=list", UpdateData);
  }

  useEffect(() => {
        const id = setInterval(() => {
          // getDataJson(server.api+"/delegates.php?statem=1", (item) => setStatem(item[0]["LastName"] + " " + item[0]["FirstName"] + " " + item[0]["SecondName"]));
          getDataJsonWoOldval(server.api+"/delegates.php?statem=list", UpdateWaitList);
          // getDataJson(server.api+"/delegates.php?statem=1", setStatemWait, statemWait);
        }, 1000);
        return () => {
          clearInterval(id);
        };
    }, []);

  return (
     <Grid container direction="column" className={styles.mainContainer}>
        {statem["idDelegate"] && 
          <Grid item> 
            <div className={styles.prepCaption}>Выступает:</div>
            <div className={styles.names}>{statem.LastName} {statem.FirstName} {statem.SecondName}</div>
          </Grid>
          }
        <Grid item>
          <div className={styles.prepCaption}>Готовятся:</div>
            { statemWait && statemWait.map((el, i) => {return(<div className={styles.names}>{el}</div>)})}
        </Grid>
     </Grid>
  );
}
