import * as React from 'react';
import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar } from '@mui/material';
import { useContextInfo } from '../ContextInfo';
import { Context } from '../Context';
import { CmdEnum } from '../ConfDriver';
import pageStyles from "./ButtonAppBar.module.css";
import ButtonOrder from './ButtonOrder';
import LogoutButton from './LogoutModal';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import { AppBar,Toolbar,IconButton } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import { GrayButton } from './Button';
import { ReactComponent as iconExit } from './icon-exit.svg';
import { ReactComponent as iconHome } from './icon-home.svg';
import { server } from '../settings';
import { deleteCookie, setCookie} from '../pages/Login/LoginForm';

export default function ButtonAppBar() {
  const { userIsLogining , setUserIsLogining,statusTest, setStatusTest,userOrder, setUserOrder,userOrderInfo,
    currentPage, setCurrentPage, user,statusApp, setShowPPT} = useContextInfo();
  const [orderInfo, setOrderInfo] = React.useState({order:false, caption : "Записаться на выступление"});
  const [color, setColor] = React.useState("orange");
console.log("PAGESTYLES", pageStyles);
console.log("PATH", window.location.pathname);

  const navigate = useNavigate();
  const loginClick = () => {
    console.log("loginClick");
    setUserIsLogining(false);
    deleteCookie('access_token');
    deleteCookie('vcs_url');
    setCookie('access_token', "", { path: '/' });
    Context.isLogin = false;
    Context.access_token = "";

    if (userIsLogining === true) {
      Context.confServer.sendCmd(CmdEnum.Unlog);    
    }

    navigate('/login', { replace: true });
  };

  React.useEffect(() => {
    // console.log("loginVcs",userOrderInfo);
  },[userOrderInfo]);

  const documentVcs = () => {
    console.log("loginVcs",currentPage);
    // navigate('/vcs', { replace: false });
    setCurrentPage("vcs");
    navigate('/doc', { replace: false });
  };

  const documentTest = () => {
    console.log("documentTest");
    setStatusTest({info:true,caption:"Demo"})
    // navigate('/vcs', { replace: true });
  };
  const documentOrder = () => {
    console.log("documentOrder");
    if (orderInfo.order) {
      setOrderInfo({order: false, caption : "Записаться на выступление"})
      Context.confServerOrder.sendCmd("unapply");
      setUserOrder(true);
    } else {
      Context.confServerOrder.sendCmd("apply");
      setOrderInfo({order: true , caption : "Вы записаны на выступление"});
      setUserOrder(false);
    }
    
    // navigate('/vcs', { replace: true });
  };
  const orderOff = () => {
    console.log("orderOff");

    Context.confServerOrder.sendCmd("off");
  }


  const documentClick = () => {
    console.log("documentClick");
    // navigate('/doc', { replace: false });
    setCurrentPage("doc");
    navigate('/doc', { replace: false });
  }

  const orderVotingList = () => {
    navigate('/votingList', { replace: true });
  }
 
  const homeClick = (event) => {
    console.log("homeClick",server.internet);
    if (server.internet) {
      console.log("homeClick navigate");

      navigate('/doc', { replace: false });
    } else {
    setCurrentPage("about")
      navigate('/', { replace: true });
    }
  }

  const presenationClick = (event) => {
    setShowPPT(true)
  }
 
  // React.useEffect(() => {
  //   if (userOrder ) {
  //     setColor("green")
  //     setOrderInfo({order: true , caption : "Вы записаны на выступление"});
      
  //   } else {
  //     setColor("white")
  //     setOrderInfo({order: false, caption : "Записаться на выступление"})
      
  //   }
  // }, [userOrder]);

  // const homeButtonColor = (window.location.pathname == "/") ? "var( --pageHoverColor)" : "var(--pageColor)";
  // const homeIconColor = (window.location.pathname == "/") ? "url(./static/icon-home-white.svg)" : "url(./static/icon-home-black.svg)";
  const homeButtonColor = (window.location.pathname == "/") ? pageStyles.homeIconActive : pageStyles.homeIcon;
  const homeIconColor = (window.location.pathname == "/") ? pageStyles.homeIconWhite : pageStyles.homeIconBlack;

  const docColor = ((window.location.pathname == "/doc") && (currentPage == "doc")) ? pageStyles.activePage : pageStyles.pageStyle;

  const vcsColor = ((window.location.pathname == "/doc") && (currentPage == "vcs")) ? pageStyles.activePage : pageStyles.pageStyle;

  const loginColor = (window.location.pathname == "/login") ? pageStyles.activePage : pageStyles.pageStyle;

  return (
    <Box>
      {(userIsLogining) &&
      <AppBar className={pageStyles.AppBar}>     
        <Toolbar className={pageStyles.Toolbar}>

         
          <>
          <div className={pageStyles.homeIconContainer} >
            <Button className={pageStyles.homeIcon + ' ' + homeIconColor + ' ' + homeButtonColor} variant="contained" onClick={homeClick}>
            </Button>
          </div>
          <div className={pageStyles.pageContainer}>
            {/* className={pageStyles.pageStyle + ' ' + docColor}  */}
              <Button className={pageStyles.pageStyle + ' ' + docColor} variant="contained" onClick={documentClick} 
            >Документы</Button>
          </div>

          <div className={pageStyles.pageContainer}>
            <Button className={pageStyles.pageStyle + ' ' + vcsColor} variant="contained" onClick={documentVcs} 
            >Видео</Button>
          </div>
          {/* { !server.internet ??
            <div className={pageStyles.pageContainer}>
              <Button className={pageStyles.presPage} variant="contained" onClick={presenationClick}>
                Презентация</Button>
            </div>
          }
           */}
           
            <div className={pageStyles.pageContainer}> 
              <ButtonOrder></ButtonOrder>
            </div>
          
          {/* <Button  size="large" sx={{mr:1,backgroundColor:"#c6bdbd"}}   variant="contained" color= "error" onClick={orderVotingList} 
          > Список записавшихся</Button> */}
          
          {/* <Avatar sx={{mr:2,ml:2}} alt="" src="/static/logo.png" />
          <Typography variant="h6" component="div" sx={{width:"500px", color: '#414141'}}>
            Государственный Совет Удмуртской Республики
          </Typography> */}
          
          </>
         

           <LogoutButton loginClick={loginClick}/> 
          {/* // : <Button className={pageStyles.pageStyle + ' ' + pageStyles.loginPage + ' ' + loginColor} variant="contained" onClick={loginClick}>Вход в систему</Button>} */}
        
        </Toolbar>
      </AppBar>

        }
   </Box>
  );
}