import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import { Grid } from "@mui/material";
import styles from "./ScreenVotingProcess.module.css";
import ScreenDate from "../components/ScreenDate";
import ScreenTime from '../components/ScreenTime';
import { FormatSeconds } from "../common/getDataJson";
import LinearWithValueLabel from '../components/LinearWithValueLabel';
import Box from '@mui/material/Box';



export default function ScreenRegProcess(props) {

  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const [lefttime, setleftTime] = React.useState(0);
  const [current, setCurrent] = React.useState(' ');
  const [total, setTotal] = React.useState(' ');

  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  // function formatSeconds(seconds) {
  //     var hours   = "" + Math.floor(seconds / 3600);
  //     var minutes = "" + Math.floor((seconds - (hours * 3600)) / 60);
  //     var formattedTime = '';

  //     if (hours > 0) {
  //         formattedTime += hours + "h ";
  //     }

  //     if (minutes > 0) {
  //         formattedTime += minutes + ":";
  //     }
     

  //     formattedTime += (seconds - (hours * 3600) - (minutes * 60)) + "";

  //     return formattedTime;
  // }

  function updateRegMode(json) {
    if (json.length == 1) {
      console.log("updateRegMode",json);
      setleftTime(FormatSeconds(json[0]["regtime_left"]));
      setCurrent(json[0]["reg_current"] == null ? 0 : json[0]["reg_current"]);
      setTotal(json[0]["reg_total"]);

    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?state=1"
    // let url = server.api + "http://localhost:3010/api/test_reg.php"

    console.log("updateRegMode", url);
    getDataJson(url, updateRegMode)
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?state=1";
      // let url = server.api + "http://localhost:3010/api/test_reg.php"
      console.log("updateRegMode", url);
      getDataJson(url, updateRegMode)
    }, 500);

    // очистка интервала
    return () => clearInterval(timer);
  });

  return (
    <Grid container className={styles.mainContainer}>
      <Grid item className={styles.name}>Государственный Совет Удмуртской Республики</Grid>
      <Grid container className={styles.centerContainer}>
        <Grid item className={styles.header}>Идет регистрация</Grid>

        {lefttime != 0 &&
        <>
          <Grid item className={styles.votingProcessContainer}>   

            <Grid item className={styles.voting} >

              <div>Осталось времени</div>
              <div>Зарегистрировались</div>
              <div>Присутствуют</div>
            </Grid>
            <Grid item className={styles.votingResStyle}>
              <div>{lefttime} </div>
              <div>{current}</div>
              <div>{total}</div>
            </Grid>
          </Grid>
        </>
        }
        </Grid>
      <div className={styles.bottomRowContainer}>
        <Grid container className={styles.bottomRow}>
          <ScreenDate />
          <ScreenTime />
        </Grid>
      </div>
    </Grid>
  );
}
