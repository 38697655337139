import ReportBody from "./ReportsBody";
import { Button } from "@mui/material";
import { useState } from "react";
import Seats from "./Seats";
import Progress from "./Progress";

function Admin() {
    const [currentAction, setCurrentAction] = useState("reports");

    return (
        <>
        <div style={{width:'100vw', alignItems:'center', justifyContent: 'center', display: "flex", paddingTop: '30px'}}>
            <Button variant="contained" onClick={() => setCurrentAction("seats")}>рассадка</Button>
            <Button variant="contained"  onClick={() => setCurrentAction("progress")}sx={{mr: '10px', ml: '10px'}}>документы</Button>
            <Button variant="contained" onClick={() => setCurrentAction("reports")}>отчеты</Button>
        </div>
        { (currentAction == "reports") ?
        <div style={{width:'100vw', height:'100vh', alignItems:'center', justifyContent: 'center', display: "flex"}}>
            <ReportBody />
        </div> : <></>
        }      
        { (currentAction == "seats") ?
        <div style={{width:'100vw', height:'100vh', alignItems:'center', justifyContent: 'center', display: "flex"}}>
            <Seats />
        </div> : <></>
        }     
        { (currentAction == "progress") ?
        <div style={{width:'100vw', height:'100vh', alignItems:'center', justifyContent: 'center', display: "flex"}}>
            <Progress />
        </div> : <></>
        }   
        </>  
    );
}

export default Admin;