import { PLACES_URL } from "../services/script";
import { Form } from "react-router-dom";
import { Input } from "@mui/material";
import { Button } from "@mui/material";
import { useState } from "react";



function Seats() {

    return (
            <>
        <form action={PLACES_URL} method="post" enctype="multipart/form-data">
            <Input type="file" name="the_file" id="fileToUpload" >Файл</Input>
            <Button type="submit" name="submit" variant="contained" sx={{ml:2}}>Загрузить</Button>
        </form>
        </>
    );
}

export default Seats;