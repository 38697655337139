import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./ScreenPresentation.module.css";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import ScreenDate from '../components/ScreenDate';
import ScreenTime from '../components/ScreenTime';
import { FormatMinutesLeft } from "../common/getDataJson";


export default function ScreenPresentation(props) {
  // console.log("Screenpresentation statem", statem);

  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  const [loadedData, setLoadedData] = React.useState(false);
  const [fio, setFio] = React.useState("");
  const [fulltime, setfulltime] = React.useState('');
  const [lefttime, setleftTime] = React.useState('');
  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const [caption, setCaption] = React.useState('');
  const [speechtype, setSpeechType] = React.useState('');

  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();



//   function formatSeconds(seconds) {
//     var hours   = Math.floor(seconds / 3600);
//     var minutes = Math.floor((seconds - (hours * 3600)) / 60);
//     var formattedTime = '';

//     if (hours > 0) {
//         formattedTime += hours + "h ";
//     }

//     if (minutes > 0) {
//         formattedTime += minutes + ":";
//     }

//     formattedTime += (seconds - (hours * 3600) - (minutes * 60)) + "";

//     return formattedTime;
// }

  function updateVoteMode(json) {
    // console.log("updateVoteMode",json,json.length,json.length == 1);
    if (json.length == 1) {
      // console.log("updateVoteMode1",json[0]["VoteMode"]);
      setFio(json[0]["LastName"] + " " + json[0]["FirstName"]  + " " + json[0]["SecondName"] );
      setfulltime(json[0]["TotalTime"]);
      setleftTime(FormatMinutesLeft(json[0]["LeftTime"]));
      setCaption(json[0]["Theme"]);
      setSpeechType(json[0]["SpeechTypeName"]);
      setLoadedData(true);

    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?statem=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
 }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?statem=1"
      console.log("updateVoteMode", url);
      getDataJson(url, updateVoteMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });


  return (
   
    <Grid container className={styles.mainContainer} item>
      <Grid item className={styles.name}>
        Государственный Совет Удмуртской Республики
      </Grid>
      {/* <Grid item className={styles.question} >{caption}</Grid> */}
      <Grid container className={styles.centerContainer}>
      {(speechtype == "Statement_Speech") ? <><Grid item className={styles.headerContainer}>
          <div className={styles.header} >Выступление с места</div>
          <div className={styles.fio}> {fio}</div>
        </Grid>
        
        </> : <> <Grid item className={styles.headerContainer}>
          <div className={styles.header} >Идет выступление</div>
          <div className={styles.fio}> {fio}</div>
        </Grid> <Grid item container className={styles.PresentationProcess}>
            <Grid item className={styles.voting} >
              <div>Время на выступление</div>
              <div>Осталось времени</div>
            </Grid>
            <Grid item className={styles.PresentationResStyle}>
            {(fulltime == "") ? <></> : <div>{fulltime} мин</div>}
            {(lefttime == "") ? <></> : <div>{lefttime} мин</div>}
            </Grid>
          </Grid>
          {lefttime == "0" ? <div className={styles.timeOver}>Время истекло</div> : <></>}
        </>
        }
      </Grid>
      <div className={styles.bottomRowContainer}>
        <Grid className={styles.bottomRow}>
          <ScreenDate />
          <ScreenTime />
        </Grid>
      </div>
    </Grid> 

  );
}
