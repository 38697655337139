import Grid from '@mui/material/Grid';
import { useContextInfo } from '../ContextInfo';
import * as React from 'react';
import { Typography } from '@mui/material';


export default function VotingList(props) {
  const { userIsLogining , setUserIsLogining,statusTest, setStatusTest,userOrder, setUserOrder,userOrderInfo} = useContextInfo();
  // let delegatesList = [];
  const { vcsUrl } = useContextInfo();
  const [delegatesList, setDelegatesList] = React.useState([]);
  React.useEffect(() => {
    console.log("VotingList",userOrderInfo);
    let dList = [];
    if (userOrderInfo["delegates_statements"]) {

      userOrderInfo["delegates_statements"].forEach(element => {
        dList.push(element.LastName+" "+ element.FirstName+" "+ element.SecondName)
      });
    }
    setDelegatesList(dList);
  },[userOrderInfo]);
  return <Typography sx={{mr:2,ml:2}}>
      Cписок записавшихся
          {delegatesList.map((delegat, index) => {
            return (
              <Typography>{delegat}</Typography>)
            }
          )
          }

    </Typography>
}