export async function getDataJsonToken(url, token,callback) {
  console.log("getDataJsonToken");
  const response = await fetch(url+"?token="+token, {
    method: 'GET'
  });
  if (response.ok) {
    const json = await response.json();
    console.log("getDataJsonToken",json);
    callback(json);
  }
    
}

export async function getDataImage(url,callback, callback2) {
  console.log("getDataImage ",url);
  const response = await fetch(url, {
    method: 'GET'
  });
  if (response.ok) {
    const text = await response.text();
    // console.log("getDataImage text",text);
    //callback('data:image/svg+xml,'+text);
    //setTimeout(callback, 1000, text);
    callback(btoa(text));
    callback2(false);
  }
    
}

export async function getDataJson(url, callback,oldval = []) {
    // console.log("getDataJson",url);
    const response = await fetch(url); 
        if (response.ok) {  //   если HTTP-статус в диапазоне 200-299
          //  получаем тело ответа (см. про этот метод ниже)
          console.log("url",url);
          const json = await response.json();
          console.log("json1",json);
          // console.log("oldval",oldval);
          //  console.log("resJson",json);
          if (json.toString() != oldval.toString()) {
            // console.log("callback");
            if (typeof callback === 'function') {
              console.log("getDataJson",json);
              callback(json);
            }
          }
        } else {
          alert(`Ошибка HTTP: ${response.status}`);
        }
  }

  export async function getDataJsonWoOldval(url, callback) {
    // console.log("getDataJson",url);
    const response = await fetch(url); 
        if (response.ok) {  //   если HTTP-статус в диапазоне 200-299
          //  получаем тело ответа (см. про этот метод ниже)
          // console.log("url",url);
          const json = await response.json();
          // console.log("json1",json);
          // console.log("oldval",oldval);
          //  console.log("resJson",json);
          
            // console.log("callback");
            if (typeof callback === 'function') {
              // console.log("getDataJson",json);
              callback(json);
            }
          
        } else {
          alert(`Ошибка HTTP: ${response.status}`);
        }
  }

  export  function FormatSeconds(secs) {
    // var hours   = "" + Math.floor(secs / 3600);
    var minutes = "" + Math.floor((secs / 60) % 60);
    var seconds = "" + Math.floor(secs % 60);
    var formattedTime = '';

     formattedTime = (minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0')); 
  return formattedTime;
  }

  export  function FormatMinutes(secs) {
    var minutes = "" + (Math.floor(secs / 60));
  return minutes;
  }


  export  function FormatMinutesLeft(secs) {
    var minutes = "" + (Math.ceil(secs / 60));
  return minutes;
  }

export default getDataJson;