import { Button, Grid, Autocomplete, TextField, Box, Container } from "@mui/material";
import { useState, useEffect } from "react";
import { getDataJson, GET_SESSIONS_URL, GET_TASKS_URL, GET_DELEGATES_URL, getReports } from "../services/script";

function ReportBody() {

    const [sessionsReady, setSessionsReady] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [delegates, setDelegates] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState("");
    const [currentTaskId, setCurrentTaskId] = useState("");
    const [currentDelegateId, setCurrentDelegateId] = useState("");

    const enableSessions = () => {
        setSessionsReady(true);
    }

    const getSessionsArray = (sessionsArray) => {
        setSessions(sessionsArray);
        enableSessions();
    }

    const getDelegatesArray = (delegatesArray) => {
        setDelegates(delegatesArray);
        console.log(delegatesArray);
    }

    const getTasksArray = (tasksArray) => {
        setTasks(tasksArray);
    }

    useEffect(() => {
        getDataJson(GET_SESSIONS_URL, getSessionsArray, enableSessions);
        getDataJson(GET_DELEGATES_URL + "&session_id=" + currentSessionId, getDelegatesArray);
        getDataJson(GET_TASKS_URL + "&session_id=" + currentSessionId, getTasksArray);
      }, []);

    useEffect(() => {
        getDataJson(GET_DELEGATES_URL + "&session_id=" + currentSessionId, getDelegatesArray);
        getDataJson(GET_TASKS_URL + "&session_id=" + currentSessionId, getTasksArray);
      }, [currentSessionId]);

    return (
        <div style={{width:'100vw', height:'100vh', alignItems:'center', justifyContent: 'center', display: "flex"}}>
        <Grid container spacing={2} direction="row" justifyContent="center" sx={{width: 900}}>
            <Grid item xs={12}>
                <p style={{textAlign: 'center'}}>Выбрать сессию</p>
            </Grid>

            <Grid item xs={12}>
                { sessionsReady ?
                    <Container sx={{
                        display: "flex",
                        width: 400,
                    }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={sessions}
                        sx={{ width: 400 }}
                        defaultValue={(sessions.length > 0) ? sessions[0].label : "Идет загрузка"}
                        renderInput={(params) => <TextField {...params} label="Сессия" />}
                        onChange = {(e, value) => {
                            setCurrentSessionId(value ? value.id : "");
                        }}
                    />
                    </Container> : <></>
                }
            </Grid>
            <Grid item xs={12}>
                <p style={{textAlign: 'center'}}>Сформировать отчеты по сессии</p>
            </Grid>

            <Grid item xs={6}>
                <Button variant="contained" sx={{margin: 1, padding: 1, width: 430, height: 60}} onClick={() => getReports('registration', currentSessionId)}>Отчет о регистрации участников</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" sx={{margin: 1, padding: 1, width: 430, height: 60}} onClick={() => getReports('themes', currentSessionId)}>Отчет по выступлениям</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" sx={{margin: 1, padding: 1, width: 430, height: 60}} onClick={() => getReports('result_questions', currentSessionId)}>Отчет по вопросам</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" sx={{margin: 1, padding: 1, width: 430, height: 60}} onClick={() => getReports('result_questions_delegates', currentSessionId)}>Отчет по вопросам с результатами по депутатам</Button>
            </Grid>

            <Grid item xs={12}>
            <Container sx={{
                        display: "flex",
                        height: 30,
                    }}></Container>
            </Grid>

            <Grid item xs={6}>
                    <Container sx={{
                        display: "flex",
                        width: 430,
                        padding: 1
                
                    }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={delegates}
                        sx={{ width: 430 }}
                        renderInput={(params) => <TextField {...params} label="Выберите депутата" />}
                        onChange = {(e, value) => {
                            setCurrentDelegateId(value ? value.delegate_id : "");
                        }}
                    />
                    </Container> 
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" sx={{margin: 1, padding: 1, width: 430, height: 60}} onClick={() => getReports('result_delegates', currentSessionId, currentDelegateId)}>Отчет по депутату</Button>
            </Grid>
            <Grid item xs={6}>
                    <Container sx={{
                        display: "flex",
                        width: 430,
                        padding: 1,
                    }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={tasks}
                        sx={{ width: 430 }}
                        renderInput={(params) => <TextField {...params} label="Выберите вопрос" />}
                        onChange = {(e, value) => {
                            setCurrentTaskId(value ? value.id : "");
                        }}
                    />
                    </Container> 
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" sx={{margin: 1, padding: 1, width: 430, height: 60}} onClick={() => getReports('result_questions_delegates', currentSessionId, "", currentTaskId)}>Отчет по вопросу</Button>
            </Grid>
            <Grid item xs={12}>
            <Container sx={{
                        display: "flex",
                        height: 30,
                    }}></Container>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained"  sx={{margin: 1, padding: 1, width: 882, height: 60}} onClick={() => getReports('all', currentSessionId)}>Все отчеты</Button>
            </Grid>
        </Grid>
        </div>
    );
}

export default ReportBody;