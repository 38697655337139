// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
import  React, {useEffect,useContext} from 'react';
// import {useEffect,useContext} from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import Typography from '@mui/material/Typography';
// import account from '../_mock/account';
import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
// import {BrowserRouter as Router,useRoutes,useNavigate} from 'react-router-dom';
// import DialogModal from '../components/DialogModal';
import { Context } from '../Context';
// import CurrentTime from '../components/CurrentTime';
// import Timer from '../components/Timer';
import ContextInfoProvider, { ContextInfo, useContextInfo } from '../ContextInfo';
import Timer from '../components/Timer';
import VotingModal from './VotingModal';
import DialogModal from '../components/DialogModal';

const screenStyle = {
  color:"white",
  background: "black",
  height: "100vh",
  fontSize: "20vh",
  userSelect: "none"
};


function FontSize(props) {
  const navigate = useNavigate();
  // const [fio, setFio] =  React.useState([""]);
  // const [userInfo,setContext]  = useContext(ContextInfo);
  const { user, updateUser,cardRegistered } = useContextInfo();
  // Context.onUpdateFio = setFio;
  
  useEffect(() => {
    // setFio(Context.votingTimeLine)
    console.log("FontSize update",cardRegistered);
    if(cardRegistered) {
      navigate('/doc', { replace: true });
    }
  }, [cardRegistered]);

const handleBoxClick = (event) => {
  console.log('Box click',Context.votingTimeLine);
  Context.votingTimeLine = Context.votingTimeLine + 1;
  if (cardRegistered) {
    navigate('/doc', { replace: true });
  }
  // setContext(Context.votingTimeLine);
  // setColors(Context.votingTimeLine)
  //updateUser();
}

  return (
    <>
    <Box className sx={{...screenStyle,pl:"20vw"}}
        display="flex"
        justifyContent="left"
        alignItems="center" 
        onClick={handleBoxClick}
        onTouchStart={handleBoxClick}
    >
      
      <Typography sx={{fontSize:"4vw"}}>
      {/* <Box sx={{ fontSize: 140, }}>{userName} {fio.LastName} {fio.FirstName}&nbsp;{fio.SecondName}</Box> */}
      {/* <Box sx={{ fontSize: 36,  }}>{fio.status}</Box> */}
      {/* <Box sx={{ fontSize: 36, m: 1 }}>{account.fraction}</Box> */}
      
      {user.fio} <br/><br/>
      { !cardRegistered   &&
        <Typography sx={{fontSize:"4vw"}}>
          Вставьте карту!
        </Typography>
    }
      </Typography>
   
    </Box>


    
    </>
  );
}

export  default function ScreenView(props) {
  
  // const userName = React.useContext(ContextInfo)
  // const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { colors,setColors } = useContextInfo();
  const { user,statusApp, regWindow, voting,votingStep,cardRegistered, regPrep} = useContextInfo();
  return (
    <React.Fragment>
        {/* <ContextInfo.Provider value={[userInfo, setContext]}>  */}
        {/* <ContextInfoProvider> */}
              {/* <FontSize userName="123456"/> */}
              {/* </ContextInfoProvider> */}
        {/* </ContextInfo.Provider> */}
        {/* <Box sx={{ fontSize: 36,  }}>{colors}</Box> */}
        <FontSize/>
        <VotingModal show={voting & cardRegistered} voteWindow={votingStep} />
        <DialogModal show={regWindow & cardRegistered} regPrep={regPrep}/>
        <Timer/>
    </React.Fragment>
  );
}
