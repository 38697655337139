import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./CountVoices.module.css";


export default function ScreenCountVoices(props) {
  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  // const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  return (

    <Grid container className={styles.mainContainer} item>
      <Grid item className={styles.header}>Идет подсчет голосов</Grid>

    </Grid>

  );
}
