import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
import { Context } from '../Context';
import pageStyles from "./ButtonAppBar.module.css";
import logoutModalStyles from "./LogoutModal.module.css";

export default function LogoutButton( props ) {
  const loginClick = props.loginClick;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button className={pageStyles.logoutIcon} variant="contained" onClick={handleOpen}></Button>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={logoutModalStyles.logoutModalStyle}>
          <Typography id="modal-modal-title" align="center">
            Вы действительно хотите выйти?
          </Typography>
          <div className={logoutModalStyles.answersContainer} >
            <Button size="large" className={logoutModalStyles.trueAnswer} color='secondary' variant="contained" onClick={() => {loginClick(); handleClose();}}
              >Да</Button>
            <Button  size="large" className={logoutModalStyles.falseAnswer} variant="outlined" autoFocus onClick={handleClose}
              >Нет</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}