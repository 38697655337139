import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { grey } from '@mui/material/colors';

import { useContextInfo } from '../ContextInfo';

export const GrayButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.getContrastText(grey[700]),
    color: theme.palette.getContrastText(grey[100]),
    backgroundColor: grey[700],
    fontSize: "1.3vw",
    '&:hover': {
      backgroundColor: grey[500],
    },
  }));
  