import * as React from 'react';
import { Viewer, SpecialZoomLevel,Worker } from '@react-pdf-viewer/core';
import { styled } from '@mui/material/styles';
import { server } from '../settings';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { grey, purple } from '@mui/material/colors';
import { GrayButton } from '../components/Button';

import { useContextInfo } from '../ContextInfo';
import zIndex from '@mui/material/styles/zIndex';

export default function ScrenPPT(props) {
    const { showPPT ,setShowPPT } = useContextInfo();
    const [urlPPT, setUrlPPT] =  React.useState("");

     React.useEffect(() => {
        console.log("showPPT",showPPT);
     },[showPPT]);

      
    React.useEffect(() => {
        console.log("showPPT",showPPT);
     },[showPPT]);

     let n = 0;
     React.useEffect(() => {
        // таймер создаётся 1 раз
       
        if (! server.debug) {
            const id = setInterval(() => {
              console.log(n++);
              if (! showPPT) {
                 clearInterval(id);
              }
              let url =server.api+"/image.php?"+Date.now();
              url = 'http://192.168.20.76/0.jpg?'+Date.now();
              setUrlPPT(url);
              if (server.internet) {
                // getDataJson(server.api+"/info.php?access_token="+Context.access_token,updateUser);
              } else {
                
                // getDataJson(server.api+"/info.php",updateUser);
              }
            //   setContext(n);
            // updateUser();
            }, 1000);
    
            return () => {
              clearInterval(id);
            };
        }
      }, [showPPT]);

    return (
       <>
   {  showPPT && 
        <div className='pptView' style={{zIndex:"101"}} onClick={() => setShowPPT(false)}>
    
            <img src={urlPPT} alt= {urlPPT}></img>
        
            <div className='pptViewBottom'> 
            <GrayButton variant="contained" onClick={() => setShowPPT(false)}>Закрыть</GrayButton>
            </div>
            
         </div>
}
       </>
        
    )
}