import React, { useState, useEffect, useContext,createContext } from 'react';
import { ConfServer } from './ConfDriver';
import { server } from "./settings";
import getDataJson from './common/getDataJson';
import { Context } from './Context';
import {CmdEnum} from './ConfDriver'
import { getCookie } from './pages/Login/LoginForm';


export const ContextInfo = createContext();

export default function ContextInfoProvider ({ children }) {
    const [user, setUser] = useState({});
    // const [userOrderL, setUser] = useState({});
    const [statusApp, setStatusApp] = useState({});
    const [regWindow, setRegWindow] = useState(false);
    const [voting, setVoting] = useState(false);
    const [voteTestTimeSec, setVoteTestTimeSec] = useState(-1);
    const [currentPage, setCurrentPage] = useState("doc");
    const [regPrep, setRegPrep] = useState(false);
    const [votingStep, setVotingStep] = useState("prepare");
    const [cardRegistered, setCardRegistered] = useState(false);
    const [userIsVoteRight, setUserIsVoteRight] = useState(false);
    const [userIsLogining, setUserIsLogining] = useState(false);
    const [showPPT, setShowPPT] = useState(false);
    const [userOrder, setUserOrder] = useState(false);
    const [userOrderList, setUserOrderList] = useState(false);
    const [userOrderInfo, setUserOrderInfo] = useState({});
    const [statusTest, setStatusTest] = useState({info:false,caption:"123"});
    const [vcsUrl, setVcsUrl] = useState("");
    console.log("server",server);

    
    React.useEffect(() => {
        console.log("----------------------------");
        Context.confServer.on('INIT', (inited) => {
            if (inited) {
              // confServer.sendCmd("rfid_get");
            } 
            console.log('server status = ' + (inited ? 'on' : 'off'));
          });

        Context.confServer.on("CMD", (cmd) => {
            console.log("Received command: " ,cmd);
            
            switch (cmd.name) {
                case "rfid":
                        // cmd_rfid_on
                        // console.log("rfid",Context);
                         if ((cmd.act == "on") && (Context.access_token != "")) {
                            Context.confServer.sendMicNum();
                        }
                    break;
                case "regprep":
                        console.log("CMD=", cmd)
                        setRegPrep(cmd.act == "on"); 
                       setRegWindow(cmd.act == "on");
                    //    setRegStep("off");
                    break;
                case "reg":
                        // setRegStep("prepare");
                        setRegPrep(false); 
                        setRegWindow(cmd.act == "on");
                    break;
                case "voteprep":
                    setVotingStep("prepare");
                    setVoting(cmd.act == "on");
                    break;
                case "vote":
                    switch (cmd.act) {
                        case "on":
                            setVotingStep("vote");
                            setVoting(true);
                            setVoteTestTimeSec(-1);
                            Context.votingTimeLine = Number(cmd.data);
                            break;
                        case "off":
                            setVotingStep("off");
                            setVoting(true);
                            break;
                        case "res":
                            setVotingStep("result");
                            setVoting(true);
                            break;
                        case "finish":
                            setVoting(false);
                            break;
                        case "test":
                            setVoteTestTimeSec(Number(cmd.data));
                            break;
    
                        default:
                            break;
                    }
                break;
                
                default:
                    break;
            }
            
        });
        




    }, []);

   
      
    const updateUser = (jsonData) => {
        let u ={};
        // Context.votingTimeLine = Context.votingTimeLine +1;
        // u["fio"] = "demo 1 "+Context.votingTimeLine;
        // u["time"] = Context.votingTimeLine*2;
        let st = {};
        st["act"] = "Иванов Василий Петрович"+Context.votingTimeLine*2;
        st["time"] = jsonData["time"];
        // console.log("updateUser",jsonData);
        // console.log("Context.access_token ",Context.access_token );
        u = jsonData["delegat"];
        u["fio"] = u["LastName"]+" "+u["FirstName"]+" "+u["SecondName"];
        if ((Context.access_token == "") && server.internet ) {
            Context.confServer.updateMicNum(0,"0");
            Context.confServerOrder.updateMicNum(0,"0");
            
        } else {
            console.log("up",jsonData["mic"],u["CardCode"]);
            Context.confServer.updateMicNum(jsonData["mic"],u["CardCode"]);
            Context.confServerOrder.updateMicNum(jsonData["mic"],u["CardCode"]);
        }
        let ds = jsonData["delegates_statements"];
        // console.log("ds",ds);
        let tmpUserOrderList = false;
        ds.forEach(delegate =>{
            console.log("ds",delegate["idDelegate"],jsonData["idDelegate"]);
            if ((delegate["idDelegate"] == jsonData["delegat"]["idDelegate"]) && (delegate["State"] == "0")) {
                tmpUserOrderList = true;
            }
        }
        )
        // tmpUserOrderList = true;

        setUserOrderList(tmpUserOrderList);
        setStatusApp(st);
        setUser(u);
        // let vcsurl = jsonData["vcsurl"];
        // setVcsUrl(vcsurl);
        setUserIsVoteRight(u["IsVoteRight"] == 1);
        setUserOrder(jsonData["order"] == "1");
        setUserOrderInfo(jsonData)
        setCardRegistered(u["CardRegistered"] == "1");
        if ((u["IsWebRegistered"] == 1) && !server.internet ){
            setCardRegistered(false);
        } ;

        // при перезагрузке страницы access токен из cookie - если есть возвращаемые значения, userIsLoginning
        if ((jsonData["idSeat"] != "") && server.internet ) {
            setUserIsLogining(true);
            setVcsUrl(getCookie('vcs_url'));
            // тут добавить апдейт ВКС
        }
        
        // if ((!cardRegistered) & u["CardRegistered"] == "1") {
        //     setVoting(false);
        // }
        
    }
    return (
      <ContextInfo.Provider value={{ user, updateUser, statusApp, 
      regWindow, voting, voteTestTimeSec, votingStep,cardRegistered,userIsVoteRight,userIsLogining,
        setUserIsLogining ,vcsUrl, setVcsUrl,statusTest, setStatusTest , userOrder, setUserOrder,
        userOrderInfo, showPPT, setShowPPT,userOrderList,setUserOrderList,regPrep, setRegPrep,
        currentPage, setCurrentPage}}>
        {children}
      </ContextInfo.Provider>
    );
  };

  

export const useContextInfo = () => useContext(ContextInfo);