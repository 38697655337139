import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
  json,
  // date,
} from "react-router-dom";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./ScreenRegResLarge.module.css";
import ScreenDateLarge from '../components/ScreenDateLarge';
import ScreenTimeLarge from '../components/ScreenTimeLarge';

// const RowVoting = (props) => {
//   let styleCircle = styles.circus;
//   if (props.color === 'green') {styleCircle = styles.circus_green;}
//   else if (props.color === 'red') {styleCircle = styles.circus_red;}
//   else if (props.color === 'yellow') {styleCircle = styles.circus_yellow;}

//   return (
//     <Grid container 
//     className={styles.resultsContainer}
//     // direction="row" 
//     // spacing={3}
//     >
//       <Grid item xs className={styles.circusCont} > <div className={styleCircle} > </div></Grid>
//       <Grid item xs={5} className={styles.votingCaption} >
//         {props.text}
//       </Grid>
//       <Grid item xs className={styles.votingNumber}>
//         {props.votingRes}
//       </Grid>
//     </Grid>
//   );
// };

function FontSize(props) {
  const navigate = useNavigate();
  // const [fio, setFio] =  React.useState([""]);
  // const [userInfo,setContext]  = useContext(ContextInfo);
  const { user, updateUser, cardRegistered } = useContextInfo();
  // Context.onUpdateFio = setFio;

  useEffect(() => {
    // setFio(Context.votingTimeLine)
    console.log("FontSize update", cardRegistered);
    if (cardRegistered) {
      navigate("/doc", { replace: true });
    }
  }, [cardRegistered]);
}
export default function ScreenRegResLarge(props) {
  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  // const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { colors, setColors } = useContextInfo();
  const [resultCode, setResultCode] = React.useState('');

  const [current, setRegistered] = React.useState('');
  const [total, setTotal] = React.useState('');
  const [quorum, setQuorum] = React.useState('');
  const {
    
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  function updateRegMode(json) {
    // console.log("updateVoteMode",json,json.length,json.length == 1);
    if (json.length == 1) {
      setRegistered(json[0]["reg_current"] == null ? 0 : json[0]["reg_current"]);
      setTotal(json[0]["reg_total"]);
      setResultCode(json[0]["ResultCode"]);
      setQuorum(json[0]["is_quorum"]);
    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?state=1" //Какая ссылка
    console.log("updateRegMode", url);
    getDataJson(url, updateRegMode)
  }, []);
  
  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?state=1"
      console.log("updateRegMode", url);
      getDataJson(url, updateRegMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });

  return (

    <Grid container className={styles.mainContainer}>
      <Grid item className={styles.name}>Государственный Совет Удмуртской Республики</Grid>
      <Grid container className={styles.centerContainer}>
        <Grid item className={styles.header} >Регистрация завершена</Grid>
        <Grid item contsiner className={styles.votingProcessContainer}>
          <Grid item className={styles.voting} >
            <div>Зарегистрировались</div>
            <div>Присутствуют</div>
          </Grid>
          <Grid item className={styles.votingResStyle}>
            <div>{current} </div>
            <div>{total} </div>
          </Grid>
        </Grid>
        {/* <Grid container className={styles.decisionContainer}> */}
          <Grid item className={styles.decision} >
              <div > {(quorum == "1") ? 
                <div className={styles.decisionYes}>Кворум есть</div> : <div className={styles.decisionNo}>Кворума нет</div>}</div>
          </Grid>
        {/* </Grid> */}
      </Grid>
      <div className={styles.bottomRowContainer}>
      <Grid container className={styles.bottomRow}>
        <ScreenDateLarge />
        <ScreenTimeLarge />
      </Grid>
    </div>
    </Grid>

  );
}
