import * as React from 'react';
import { blue } from '@mui/material/colors';
import {useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import Article from '@mui/icons-material/Article';
import Avatar from '@mui/material/Avatar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import PollIcon from '@mui/icons-material/Poll';
import PropTypes from 'prop-types';
import RestoreIcon from '@mui/icons-material/Restore';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { AppBar,Toolbar,IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useContextInfo } from '../ContextInfo';
import getDataJson from '../common/getDataJson'
// import {Context} from '../Context'
// import { ContextInfo } from "../ContextInfo";
import CurrentTime from './CurrentTime';
import {server} from '../settings.js'
import { color } from '@mui/system';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import { GrayButton } from './Button';
import ButtonOrder from './ButtonOrder';
import bottomRowStyles from './BottomRow.module.css'
import pageStyles from './ButtonAppBar.module.css'


const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});





export default function BottomRow(props) {
    //  const classes = useStyles();
     const navigate = useNavigate();
     const { user,statusApp, setShowPPT, currentPage, setCurrentPage } = useContextInfo();
     React.useEffect(() => {
      console.log("redraw",statusApp,user);
      
    }, []);
    
    const homeClick = (event) => {
      // setCurrentPage("about")
      navigate('/', { replace: true });
    } 

    const presenationClick = (event) => {
      setShowPPT(true)
    }


    
    let fio = statusApp.act+" --";  
    return <Paper className={bottomRowStyles.paper} >
         <AppBar className={bottomRowStyles.appBar}>
            <Toolbar className={bottomRowStyles.toolbar}>
              <IconButton className={bottomRowStyles.iconButton} aria-label="open drawer"
              onClick={homeClick}
              >
                <HomeIcon
                 />
              </IconButton>
              {/* <StyledFab color="secondary" aria-label="add">
                <AddIcon />
              </StyledFab> */}
              <Box className={bottomRowStyles.userFio}>
              {user.fio} 
                </Box>
                <div className={pageStyles.pageContainer}> 
                  <ButtonOrder></ButtonOrder>
                </div>
              {/* <GrayButton size="large" onClick={presenationClick}>Записаться на выступление</GrayButton> */}
              <div className={pageStyles.pageContainer}>
                <Button className={pageStyles.presPage} variant="contained" onClick={presenationClick}>
                  Презентация</Button>
              </div>
              {/* <Box sx={{pl:10}}>
                Документы
                </Box> */}
               
              <IconButton className={bottomRowStyles.iconButton}>
                <MoreIcon />
              </IconButton>
              
              <Box className={bottomRowStyles.timeStyle}>
                {statusApp.time}
                </Box>
            </Toolbar>
      </AppBar>
    </Paper>
}