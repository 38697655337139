
import * as React from 'react';
import BottomRow from '../components/BottomRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DocTree from '../components/DocTree';
import DocTree1 from '../components/DocTree1';
import getDataJson from '../common/getDataJson';
import Grid from '@mui/material/Grid';
import LinearWithValueLabel from '../components/LinearWithValueLabel';
import Paper from '@mui/material/Paper';
import PdfViewer from './PdfViewer';
import PeopleList from '../components/PeopleList';
import PeopleListStack from '../components/PeopleListStack';
import Stack from '@mui/material/Stack';
import Timer from '../components/Timer';
import Typography from '@mui/material/Typography';
import { server } from '../settings';
import { styled } from '@mui/material/styles';
import { useContextInfo } from '../ContextInfo';
import { accountList,acl1,acl2,acl3,acl4} from '../_mock/account';
import { documentList} from '../_mock/account';
import DialogModal from '../components/DialogModal';
import VotingModal from './VotingModal';
import Vcs from './Vcs';
import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
import { height } from '@mui/system';
import { useRef } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import VotingList from './VotingList';
import ScreenPPT from './ScreenPPT';
import docStyles from './Documents.module.css';
import styles from '../App.css';
import VcsMain from './VcsMain';


export default function Documents(props) {
  // const [open, setOpen] = React.useState(props.info.doc);
  const { user,statusApp,regWindow, voting,votingStep, cardRegistered,userIsVoteRight,
    userIsLogining,currentPage ,userOrderInfo, regPrep,showPPT, setShowPPT } = useContextInfo();
  const [open, setOpen] = React.useState(0);
  const [layout, setLayout] = React.useState(<></>);
  const [docList, setDoclist] = React.useState([]);
  const [showOrderList, setShowOrderList] = React.useState(false);
  const navigate = useNavigate();
  const menuRef = useRef();
  let info ={doc:"doc1"}
  console.log("Documents");
  React.useEffect(() => {
    getDataJson(server.api+"/doc_list.php", setDoclist);
    console.log("userOrderInfo",userOrderInfo);
  }, []);

  React.useEffect(() => {
    console.log("React.useEffect cardRegistered",cardRegistered ,server.internet);
    if(!cardRegistered) {
      if (!server.internet) {
        console.log("React.useEffect cardRegistered1");
        navigate('/', { replace: true });
      }
    }
    
  }, [cardRegistered]);

  React.useEffect(() => {
    console.log("userIsLogining",userIsLogining);
    
  }, [userIsLogining]);

  React.useEffect(() => {
    console.log("Doc 11",menuRef.current);
    menuRef.current.scrollTo(0, 0);
    
  }, [open])

  React.useEffect(() => {
    console.log("currentPage=",currentPage);
    if (currentPage == "doc") {
      demoClick()
    } else {
      demoClick2()
    }
    
  }, [currentPage])
  
  const demoClick = () => {
    console.log("demoClick");
    setLayout(<>
    <Grid container 
    justifyContent="space-between">
        <Grid item xs={3} sx={{background:"#f5f5f5", boxShadow: 2}}  >
          <DocTree docList={docList} onOpen={setOpen}></DocTree>
        </Grid>
       
        <Grid ref={menuRef} item xs={ "auto"}  sx={{p: 1, height: "100vh", overflowY: 'auto' }} >
          <PdfViewer doc={open} 
            
            
            />    
        </Grid>
        { server.internet &&
            <>
            <Grid item xs={3} sx={{p:1}} direction="column" >

            <Vcs></Vcs>
            {showOrderList &&  <VotingList></VotingList> }
            </Grid>
            
            </>
        } 

        
    </Grid>
    
    
    </>)
    // navigate('/doc', { replace: false });
  }
  const demoClick2 = () => {
    console.log("demoClick");
    setLayout(<>
      <Grid container sx={{background:"",height:"400px"}}  
      justifyContent="space-between">
          <Grid item sx={{background:"#f5f5f5", boxShadow: 2}}  >
            {/* <DocTree docList={docList} onOpen={setOpen}></DocTree> */}
          </Grid>
         
          <Grid style={{visibility: "hidden",display:"none"}} ref={menuRef} 
          item xs={"auto"}  
          sx={{p: 1, height: "100vh", overflowY: 'auto' }} >
            <PdfViewer doc={open} />    
          </Grid>
          { server.internet &&
          <>
            <Grid item xs={showOrderList?10:12} sx={{p:1}} direction="column" >
           
            <Vcs></Vcs>
       
            </Grid>
            {showOrderList && 
            <Grid item xs={2} sx={{background:"blue"}} > 
                  <VotingList></VotingList> 
            </Grid>
            }
             </>
          } 
  
          
      </Grid>
      
      
      </>)
    // navigate('/doc', { replace: false });
  }
        // menuRef.current.parentElement.scrollTo(0, 0);

  React.useEffect(() => {
          console.log("VotingList",userOrderInfo);
          if (userOrderInfo["delegat"]){
            if (userOrderInfo["delegat"]["LastName"] == "Невоструев" || userOrderInfo["delegat"]["LastName"] == "Demo") {
              setShowOrderList(true);
            }
          }
          
  },[userOrderInfo])

  console.log("docStyles.mainContainer", docStyles.mainContainer);


  return ( 
  <>
   
    <Grid container className={docStyles.mainContainer}>
      <Grid 
        style={{visibility: (currentPage == "vcs")? "hidden" :"visible", display:(currentPage == "vcs")? "none": ""}} 
        item xs={3}
        className={docStyles.docListStyle} 
        >
            <DocTree docList={docList} onOpen={setOpen}></DocTree>
      </Grid>
         
      <Grid style={{visibility: (currentPage == "vcs")? "hidden" :"visible", display:(currentPage == "vcs")? "none": ""}} ref={menuRef} 
      item 
      // xs={server.internet ? 6 : 9}  
      xs={9}
      className={docStyles.docContainer}
      >
        <PdfViewer doc={open} 
        sesionCaption={userOrderInfo.sesionCaption} 
        sesionCode={userOrderInfo.sesionCode} 
        />    
      </Grid>
          
      { server.internet &&
        < >

          <div>
            {/* <VcsMain></VcsMain> */}
            <Vcs></Vcs>
          </div>
         </>
          } 
          
  
          
    </Grid>
      {server.internet ?  <></> : <BottomRow/> }
      <DialogModal show={(regWindow & userIsVoteRight)}  regPrep={regPrep}/>
      <VotingModal show={voting} voteWindow={votingStep} />
      <ScreenPPT show={showPPT}  />
      
      <Timer/>
  </>
    

  )}