import React, {useEffect}  from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Context } from '../Context';
import dialogModalStyles from './DialogModal.module.css'
import RegPrepareModal from '../pages/RegPrepareModal';

  export default function DialogModal(props) {
    const [open, setOpen] = React.useState(props.show);
    const [title, setTitle] = React.useState("Процедура регистрации");
    // let {show} = props;
    const [showlabel, setShow] = React.useState(true);
    const handleOpen = () => setOpen(false);
    const handleClose = () => setOpen(false);
    const sendCommand = () => {
      Context.confServer.sendCmd("R");
      setTitle('Вы зарегистрированы');
      setShow(false);
    }
    
    useEffect(() => { //если диалоговое окно закрывается, в дальнейшем регистрация будет идти заново
      console.log("----------------",props.show);
      if (props.show) {
        setTitle("Процедура регистрации");
        setShow(true);
        return 
      } else return
    }, [props.show]
    );
  
    return (
     
        <Modal
        hideBackdrop
          open={Boolean(props.show)}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {  props.regPrep ? <RegPrepareModal /> :
            <Box className={dialogModalStyles.dialogModalStyle}>

            <div  className={dialogModalStyles.captionModal}> {title} </div>

        {showlabel && <Button
                      fullWidth
                      className={dialogModalStyles.buttonCss}
                      color='secondary'
                      variant="contained"
                      onTouchStart={sendCommand}
                      onMouseDown={sendCommand}
                      >
                      Нажмите эту кнопку <br/>для прохождения регистрации
                    </Button>
            }
          
                   
          </Box>
  }
        </Modal>
 
    );
  }
