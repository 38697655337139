import React, { useEffect, useContext ,useState} from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo.js";
import { Grid } from "@mui/material";
import ScreenPresPrep from "./ScreenPresPrep.js";
import ScreenPres from "./ScreenMainPage.js";
import { server } from "../settings.js";
import getDataJson from "../common/getDataJson.js";
import ScreenVotingPrepare from "./ScreenVotingPrepare.js";
import ScreenVotingProcess from "./ScreenVotingProcess.js";
import ScreenPresentation from './ScreenPresentation.js';
import ScreenVotingRes from './ScreenVotingRes.js';
import ScreenMainPage from './ScreenMainPage.js';
import ScreenCountVoices from './ScreenCountVoices.js';


export default function ScreenOBS(props) {
// https://zakon.udmgossovet.ru/api/delegates.php?state=1
const [state, setState] = useState("0");

function UpdateData(json) {
  //console.log("json=", json)
  setState(json[0]["VoteMode"])
}

useEffect(() => {
  const id = setInterval(() => {
      getDataJson(server.api+"/delegates.php?state=1", UpdateData);
  }, 1000);
  return () => {
    clearInterval(id);
  };
}, []);

useEffect(() => {
  if ((state == "1") || (state == "2") || (state == "3") || (state == "4")) {
    document.body.style.backgroundColor = "#EFF4F9";
  } else {
    document.body.style.backgroundColor = "#282a4100";
  }
}, state);



  return (
    
    
      <Grid container>
       
        <Grid item xs={12}>
          {/* { state == "0" && <ScreenMainPage />} */}
      
          { state == "1" && <ScreenVotingPrepare />}
          { state == "2" && <ScreenVotingProcess />}
          { state == "3" && <ScreenCountVoices />}
          { state == "4" && <ScreenVotingRes />}
          {/* { state == "5" && <ScreenMainPage />} */}
        </Grid>
      </Grid>
    
  );
}
