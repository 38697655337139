import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import { Grid } from "@mui/material";
import styles from "./ScreenVotingProcessLarge.module.css";
import ScreenDateLarge from '../components/ScreenDateLarge';
import ScreenTimeLarge from '../components/ScreenTimeLarge';
import { FormatSeconds } from "../common/getDataJson";

export default function ScreenVotingProcessLarge(props) {

  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const [lefttime, setleftTime] = React.useState(0);
  const [caption, setCaption] = React.useState("");
  const [registered, setRegistered] = React.useState('');
  const [za, setZa] = React.useState('');
  const [protiv, setProtiv] = React.useState('');
  const [vozd, setVozd] = React.useState('');

  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  // function formatSeconds(seconds) {
  //     var hours   = "" + Math.floor(seconds / 3600);
  //     var minutes = "" + Math.floor((seconds - (hours * 3600)) / 60);
  //     var formattedTime = '';

  //     if (hours > 0) {
  //         formattedTime += hours + "h ";
  //     }

  //     if (minutes > 0) {
  //         formattedTime += minutes + ":";
  //     }
     

  //     formattedTime += (seconds - (hours * 3600) - (minutes * 60)) + "";

  //     return formattedTime;
  // }
 
  // function formatSeconds(secs) {
  //   var minutes = "" + Math.floor((secs / 60) % 60);
  //   var seconds = "" + Math.floor(secs % 60);
  //   var formattedTime = '';

  //    formattedTime = (minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0')); 
  // return formattedTime;
  // }
  
  function updateVoteMode(json) {
    if (json.length == 1) {
      console.log("updateVoteMode",json);
      setleftTime(FormatSeconds(json[0]["LeftTimeSec"]));
      setCaption(json[0]["Caption"]);
      setRegistered(json[0]["Registered"]);
      setZa(json[0]["Za"]);
      setProtiv(json[0]["Protiv"]);
      setVozd(json[0]["Vozd"]);

    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?result=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
  }, []);
  
  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?result=1"
      console.log("updateVoteMode", url);
      getDataJson(url, updateVoteMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });

  return (
    <Grid container className={styles.mainContainer} item>
    <Grid item className={styles.name}>Государственный Совет Удмуртской Республики</Grid>
    {/* <div className={styles.question} >«{userOrderInfo.votingres && userOrderInfo.votingres.Caption}»</div> */}
    {/* <Grid item className={styles.question} >{caption.replace(/"/g, '')}</Grid>       */}
    <Grid container className={styles.centerContainer}>
      <Grid item className={styles.header}>Идет голосование</Grid>
      {lefttime != 0 &&
        <Grid item contsiner className={styles.votingProcessContainer}>
          <Grid item className={styles.voting} >
            <div>Осталось времени</div>
            <div>Проголосовали</div>
            <div>Присутствуют</div>
          </Grid>
          <Grid item className={styles.votingResStyle}>
            <div>{lefttime} </div>
            {/* <div>{parseInt(za) + parseInt(protiv) + parseInt(vozd)} </div> */}
            <div>{isNaN(parseInt(za) + parseInt(protiv) + parseInt(vozd)) ? " " : (parseInt(za) + parseInt(protiv) + parseInt(vozd))} </div>
            <div>{registered} </div>
          </Grid>
        </Grid>
      }
    </Grid>
    <div className={styles.bottomRowContainer}>
      <Grid container className={styles.bottomRow}>
        <ScreenDateLarge />
        <ScreenTimeLarge />
      </Grid>
    </div>
  </Grid>
  );
}
