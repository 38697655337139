import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContextInfo } from '../ContextInfo';
// import VotingList from './VotingList';
import * as React  from 'react';
import {useEffect,useContext} from 'react';
// import AspectRatio from '@mui/joy/AspectRatio';
// import { Typography } from '@mui/material';
import vcsStyles from './Vcs.module.css'

export default function VcsMain(props) {
  const { vcsUrl ,userOrderInfo,currentPage } = useContextInfo();
  const [styleVCS, setStyle] = React.useState(vcsStyles.min);
  const [direction, setDirection] = React.useState("column");
  // console.log("props vcs",props);
  useEffect(() => {
    // setFio(Context.votingTimeLine)
    console.log("currentPage update",currentPage);
    if (currentPage == "vcs" ) {
      setStyle(vcsStyles.max)
    }
    if (currentPage == "doc" ) {
      setStyle(vcsStyles.min)
    }
    if (currentPage == "about") {
      setStyle(vcsStyles.max)
    }
  }, [currentPage]);

  useEffect(() => {
    console.log("vcsUrl=",vcsUrl);
  },[vcsUrl]);

  return  (<div className={styleVCS}>
    
        <iframe allowfullscreen="allowfullscreen"  
              allow="microphone; camera; autoplay" src={vcsUrl}></iframe> 
      </div>)


}