import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { fontSize } from '@mui/system';
import getDataJson from "../common/getDataJson";
import { server } from '../settings';
import prepStyles from './RegPrepareModal.module.css';

export default function RegPrepareModal(props) {

 return (
  // <Modal
  
  //   // open={Boolean(props.show)}
  //   // onClose={handleClose}
  //   aria-labelledby="modal-modal-title"
  //   aria-describedby="modal-modal-description"
  // >
        <Box  className={prepStyles.dialogModalStyle}>
          <Grid  item >
              <Typography className={prepStyles.captionModal}>
               Приготовиться к регистрации 
              </Typography>
          </Grid>

          {/* <Grid  item >
              <CircularProgress color="inherit" />
              
          </Grid> */}
          <Grid item className={prepStyles.downloadIconContainer}>
            <img src="./static/icon-download.svg" className={prepStyles.downloadIcon}>
            </img>
          </Grid>
           
        </Box>
        // </Modal>
 )}
   