import  React, {useEffect,useContext} from 'react';
import { ContextInfo } from "../ContextInfo";
import { Context } from "../Context";
import { server } from "../settings";
import getDataJson from "../common/getDataJson";
import {useNavigate } from 'react-router-dom';
import { useContextInfo } from '../ContextInfo';
import { getCookie } from '../pages/Login/LoginForm';



const Timer = () => {
  // const userName = React.useContext(ContextInfo);
  const { updateUser } = useContextInfo();
  const updateCont = (json) => {
    console.log("updateCont",json);
  };
  // const navigate = useNavigate();
  // const updateCont = (json) => {
  //   console.log("updateContext",json);
  //   let registered = "не зарегистрирован";
  //     Context.delegat.CardCode = json.delegat.CardCode;
  //     Context.delegat.Registered = json.delegat.Registered;
  //     Context.delegat.CardRegistered = json.delegat.CardRegistered;
  //     Context.delegat.IsVoteRight = json.delegat.IsVoteRight;

  //   if (json.delegat["CardRegistered"] == "1") {
  //     registered = "зарегистрирован";
  //     Context.status = "registered";
      

  //     // setContext(json.delegat.Name +" "+ json.delegat.LastName+" "+json.delegat.FirstName+" "+json.delegat.SecondName+n);
  //     setContext( json.delegat.LastName+" "+json.delegat.FirstName+" "+json.delegat.SecondName);
  //   } else {
  //     setContext("Вставьте карту");
  //     console.log("document.location.pathname",document.location.pathname);
  //     Context.status = "";
  //     if (document.location.pathname != "/screen") {
  //       navigate('/view/screen', { replace: true })
  //     }
  //   }
    
  // }
  let n = 0;
  React.useEffect(() => {
    // таймер создаётся 1 раз
    if (! server.debug) {
        const id = setInterval(() => {
          // console.log(n++);
          if (server.internet) {
            //getDataJson(server.api+"/info.php?access_token="+Context.access_token,updateUser);

            if ((Context.access_token == undefined) || (Context.access_token == "")) {
              console.log("Запрос без токена в контексте");
              const access_token_from_cookie = getCookie("access_token");
              Context.access_token = access_token_from_cookie;
              getDataJson(server.api+"/info.php?access_token="+access_token_from_cookie,updateUser);
            } else {
              console.log("Запрос с токеном в контексте");
              getDataJson(server.api+"/info.php?access_token="+Context.access_token,updateUser);
            };

          } else {
            getDataJson(server.api+"/info.php",updateUser);
          }
        //   setContext(n);
        // updateUser();
        }, 1000);

        return () => {
          clearInterval(id);
        };
    }
  }, [Context.access_token]);

 
};

export default Timer;