import { useContextInfo } from '../ContextInfo';
import {useEffect,useContext} from 'react';
import * as React from 'react';
import vcsStyles from './Vcs.module.css'


export default function Vcs(props) {
  const { vcsUrl ,userOrderInfo,currentPage } = useContextInfo();
  const [showOrderList, setShowOrderList] = React.useState(false);
  const [styleVCS, setStyle] = React.useState(vcsStyles.min);
  useEffect(() => {
    // setFio(Context.votingTimeLine)
    console.log("currentPage update",currentPage);
    if (currentPage == "vcs" ) {
      setStyle(vcsStyles.max)
    }
    if (currentPage == "doc" ) {
      setStyle(vcsStyles.min)
    }
  }, [currentPage]);

  return  (<div className={styleVCS}>
    
    <iframe allowfullscreen="allowfullscreen"  
          allow="microphone; camera; autoplay" src={vcsUrl}></iframe> 
  </div>
)
              
          

}