import logo from './logo.svg';
import React from "react";
import './App.css';
import {BrowserRouter as Router, useRoutes} from 'react-router-dom';
import { ContextInfo ,useContextInfo} from './ContextInfo';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ScreenView from './pages/ScreenView';
import Documents from './pages/Documents';
import About from './pages/About';
import {LoginForm} from './pages/Login';
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
import Vcs from './pages/Vcs';
import VotingList from './pages/VotingList';
import { server } from './settings';
import ScreenVotingRes from './pages/ScreenVotingRes';
import ScreenVotingProcess from './pages/ScreenVotingProcess';
import ScreenPresentation from './pages/ScreenPresentation';
import ScreenMainPage from './pages/ScreenMainPage';
import ScreenVotingPrepare from './pages/ScreenVotingPrepare';
import ScreenPresPrep from './pages/ScreenPresPrep';
import ScreenPredsedatel from './pages/ScreenPredsedatel';
import ScreenHall from './pages/ScreenHall';
import ReportBody from './components/ReportsBody';
import ScrenPPT from './pages/ScreenPPT';
import Admin from './components/Admin';
import { dark, light } from '@mui/material/styles/createPalette';
import ScreenOBS from './pages/ScreenOBS';
import { Box } from '@mui/material';
import ScreenCountVoices from './pages/ScreenCountVoices';
import ScreenRegistration from './pages/ScreenRegistration';
import ScreenRegistrationDark from './pages/ScreenRegistrationDark';
import ScreenRegPrepare from './pages/ScreenRegPrepare';
import ScreenRegPrepareDark from './pages/ScreenRegPrepareDark';
import ScreenRegRes from './pages/ScreenRegRes';
import ScreenRegResDark from './pages/ScreenRegResDark';
import ScreenRegProcessDark from './pages/ScreenRegProcessDark';
import ScreenRegProcess from './pages/ScreenRegProcess.js';

// 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */

function AppRoutes(props) {
  // console.log("AppRoutes",props);
  return  useRoutes(
   
    [
      {path: '/', element: <ScreenView />},
      {path: '/screen', element: <ScreenView />},
      {path: '/doc', element: <Documents />},
      {path: '/admin', element: <Admin />},
      {path: '/obs', element: <ScreenOBS />},
      // {path: '/ppt', element: <ScrenPPT />},
      {path: '/screen_res', element: <ScreenVotingRes />},
      {path: '/screen_v', element: <ScreenVotingProcess />},
      {path: '/screen_pres', element: <ScreenPresentation />},
      {path: '/screen_main', element: <ScreenMainPage />},
      {path: '/screen_prep', element: <ScreenVotingPrepare />},
      {path: '/screen_presprep', element: <ScreenPresPrep />},
      {path: '/screen_p', element: <ScreenPredsedatel />},
      {path: '/screen_hall', element: <ScreenHall />},
      {path: '/screen_c', element: <ScreenCountVoices />},
      {path: '/screen_reg', element: <ScreenRegistration />},
      {path: '/screen_reg_dark', element: <ScreenRegistrationDark />},
      {path: '/screen_reg_prep', element: <ScreenRegPrepare />},
      {path: '/screen_reg_res', element: <ScreenRegRes />},

      // {path: '/about', element: <About />},
    ]
  )
}
function AppRoutesExt(props) {
  // console.log("AppRoutesExt",props);
  const routes = useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
          {path: '/', element: <LoginForm />},
          // {path: '/', element: <About />},
          {path: '/login', element: <LoginForm />},
          {path: '/doc', element: <Documents />},
         
          // {path: '/ppt', element: <ScreenView />},
          
          {path: '/vcs', element: <Vcs direction="row"/>},
          {path: '/screen_res', element: <ScreenVotingRes />},
          {path: '/screen_v', element: <ScreenVotingProcess />},
          {path: '/screen_pres', element: <ScreenPresentation />},
          {path: '/screen_main', element: <ScreenMainPage />},
          {path: '/screen_prep', element: <ScreenVotingPrepare />},
          {path: '/screen_presprep', element: <ScreenPresPrep />},
          {path: '/screen_p', element: <ScreenPredsedatel />},
          {path: '/screen_c', element: <ScreenCountVoices />},
          {path: '/screen_reg_prep', element: <ScreenRegPrepare />},
          {path: '/screen_reg_res', element: <ScreenRegRes />},

          // {path: '/screen_hall', element: <ScreenHall />},


      // {path: '/screen', element: <ScreenView />},
      // {path: '/doc', element: <Documents />},
      // {path: '/about', element: <About />},
      ]
    },
    { path: '/obs', element:   <ScreenOBS />},
    { path: '/screen_p', element:   <ScreenPredsedatel />},
    {path: '/screen_hall', element: <ScreenHall />},
    {path: '/screen_reg', element: <ScreenRegistration />},
    {path: '/screen_reg_dark', element: <ScreenRegistrationDark />},
    {path: '/screen_reg_prep_d', element: <ScreenRegPrepareDark />},
    {path: '/screen_reg_prep', element: <ScreenRegPrepare />},
    {path: '/screen_reg_res', element: <ScreenRegRes />},
    {path: '/screen_reg_res_d', element: <ScreenRegResDark />},
    {path: '/screen_reg_proc', element: <ScreenRegProcess />},
    {path: '/screen_reg_proc_d', element: <ScreenRegProcessDark />},

  ]
  );
  const routesLogin = useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
          {path: '/', element: <About />},
          {path: '/login', element: <LoginForm />},
          {path: '/screen', element: <ScreenView />},
          {path: '/doc', element: <Documents />},
          // {path: '/ppt', element: <ScreenView />},
          
          {path: '/vcs', element: <Vcs  direction="row" />},
          {path: '/votingList', element: <VotingList />},
          {path: '/screen_res', element: <ScreenVotingRes />},
          {path: '/screen_v', element: <ScreenVotingProcess />},
          {path: '/screen_pres', element: <ScreenPresentation />},
          {path: '/screen_main', element: <ScreenMainPage />},
          {path: '/screen_prep', element: <ScreenVotingPrepare />},
          {path: '/screen_presprep', element: <ScreenPresPrep />},
          // {path: '/screen_reg_res_d', element: <ScreenRegResDark />},

          {path: '/screen_c', element: <ScreenCountVoices />},
          // {path: '/screen_reg_prep', element: <ScreenRegPrepare />},
          // {path: '/screen_reg_res', element: <ScreenRegRes />},

      // {path: '/about', element: <About />},
      ]
    },
    {path: '/screen_p', element: <ScreenPredsedatel />},
    {path: '/screen_hall', element: <ScreenHall />},
    {path: '/screen_reg', element: <ScreenRegistration />},
    {path: '/screen_reg_dark', element: <ScreenRegistrationDark />},
    {path: '/screen_reg_prep', element: <ScreenRegPrepare />},
    {path: '/screen_reg_res', element: <ScreenRegRes />},
    {path: '/screen_reg_res_d', element: <ScreenRegResDark />},
    {path: '/screen_reg_proc', element: <ScreenRegProcess />},
    {path: '/screen_reg_proc_d', element: <ScreenRegProcessDark />},
  ]
  )
  return props.login ? routesLogin :routes ;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#E6E8E7',
      dark: 'none',
      contrastText: 'black',
    },
    secondary: {
      main: "#E73550",
      dark: '#c5243c',
      contrastText: 'white',
    },
    info: {
      main: "#282828",
      dark: "#181818",
    } 
  },
  typography: {
    allVariants: {
      // fontFamily: ['Monsterrat'],
      fontSize: 20,
      textTransform: 'none',
      lineHeight: 1.3,
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         height: 70,
  //         transition: '0.3s ease all',
  //         zIndex: 100,
          
  //       },
  //     },
  //   },
  // },
});

function App() {
  const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { userIsLogining } = useContextInfo();
  // const [userInfo, setContext] = React.useState("Красильникова Татьяна Владимировна");

  console.log("theme is", theme);
  return (

    <ThemeProvider theme={theme}>
      <Router>
        {server.internet ? <AppRoutesExt login={userIsLogining} /> : <AppRoutes/>  }
      </Router>
    </ThemeProvider>
    
      // <Router>
      //    {/* <ContextInfo.Provider value={[userInfo, setContext]}> */}
      //    { internal  &&
      //     <AppRoutes internal="false" />
      //    } 
      //    { !internal  &&
      //     <AppRoutesExt internal="false" />
          
      //    }
      //     {/* </ContextInfo.Provider> */}
      // </Router>
     
  );
}

export default App;
