import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DocTree from '../components/DocTree';
import PeopleList from '../components/PeopleList';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import {accountList,acl1,acl2,acl3,acl4, infoStatic} from '../_mock/account';
import PeopleListStack from '../components/PeopleListStack';
import LinearWithValueLabel from '../components/LinearWithValueLabel';
import { CircularProgress } from '@mui/material';
import { Container } from '@mui/material';
import { Card } from '@mui/material';

import {Context} from '../Context'
import {CmdEnum} from '../ConfDriver'
import ContextInfoProvider, { ContextInfo, useContextInfo } from '../ContextInfo';
import getDataJson from "../common/getDataJson";
import { server } from '../settings';
import votingStyles from './Voting.module.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const selectCss = votingStyles.selectCss;
const notselectCss = votingStyles.notselectCss;
const selectCssZa = selectCss + ' ' + votingStyles.selectCssZa;
const selectCssProtiv = selectCss + ' ' + votingStyles.selectCssProtiv;
const selectCssVozd = selectCss + ' ' + votingStyles.selectCssVozd;

export function getURLVarArr() {
  var data = [];
  var query = String(document.location.href).split('?');
  if (query[1]) {
    var part = query[1].split('&');
    for (let i = 0; i < part.length; i++) {
      var dat = part[i].split('=');
      data[dat[0]] = dat[1];
    }
  }
  return data;
}

export function Voting1() {
  return (
   
      <Grid container spacing={0}
      sx={{ flexGrow: 1, height:"90vh",background:"" }}
      alignItems="center"
      >
        <Grid container item
          sx={{background:"",height:"16vh",}}
          
        >
          <Grid item xs={6} 
            sx={{background:"",textAlign: 'center'}}
            >
             
            <Typography variant="h3">
              {infoStatic.info}
                
            </Typography>
            
          </Grid>
          <Grid item xs={6}>
           <Typography variant="h7">
                Председатель 
            </Typography>
            <Typography variant="h6">
            {infoStatic.predsedatel}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={3}
            direction="column"
            sx={{background:"",height:"70vh", p:2}}
            // alignItems="flex-start"
            alignItems = "center"
        >
          <Grid item xs={2}  >
            <PeopleListStack caption="На вопрос" list={acl1}/>
          </Grid>
          <Grid item  sx={{background:"",height:"100%"}}>
              <Divider orientation="vertical"></Divider>
          </Grid>
          <Grid item xs={2}>
            <PeopleListStack caption="Выступление" list={acl2}/>
          </Grid>
          <Grid item  sx={{background:"",height:"100%"}}>
              <Divider orientation="vertical"></Divider>
          </Grid>
          <Grid item xs={2}>
            <PeopleListStack caption="Выступление" list={acl3}/>
          </Grid>
          <Grid item  sx={{background:"",height:"100%"}}>
              <Divider orientation="vertical"></Divider>
          </Grid>
          <Grid item xs={2} >
            <PeopleListStack caption="Регламент" list={acl4}/>
          </Grid>
          
        </Grid>
        
      </Grid>
    
  );
}

const ChoiseElement = (props) => {
  const classNameFromChoises = props.className;
  
return (

  <Grid item 
  // sx={props.select} 
  className={props.select} 
  >
                        <Button
                          color={props.color}
                          className={votingStyles.buttonCss + ' ' + classNameFromChoises + ' ' + props.select}
                          sx={{...props.css}}
                          variant="contained"
                          onClick={props.onClickFunct} 
                          onMouseDown={props.onClickFunct}
                          onTouchStart={props.onClickFunct}
                        >
                        {props.caption}
                        </Button>
              </Grid>

)

}

export default function Voting(props) {



  const [choise, setChoise] = React.useState("");
  const [selectZa, setSelectZa] = React.useState(notselectCss);
  const [selectProtiv, setSelectProtiv] = React.useState(notselectCss);
  const [selectVozd, setSelectVozd] = React.useState(notselectCss);
  const [showVoteButtons, setShowVoteButtons] = React.useState(true);
  const { user, updateUser,cardRegistered, userIsVoteRight, voteTestTimeSec} = useContextInfo();

  console.log("select", selectCss);
  console.log("notselect", notselectCss);

  function handleSubmitZa(e) {
    // e.preventDefault();
    Context.confServer.sendCmd(CmdEnum.Btn_ZA);
    Context.votingChoice = "Вы проголосовали «за»";
    setChoise("ЗА");
    setSelectZa(selectCssZa);
    setSelectVozd(notselectCss);
    setSelectProtiv(notselectCss);
  }

  function handleSubmitProtiv(e) {
    // e.preventDefault();
    Context.confServer.sendCmd(CmdEnum.Btn_PROTIV);
    Context.votingChoice = "Вы проголосовали «против»";
    setChoise("ПРОТИВ");
    setSelectZa(notselectCss);
    setSelectVozd(notselectCss);
    setSelectProtiv(selectCssProtiv);
  }

  function handleSubmitVozder(e) {
    // e.preventDefault();
    Context.confServer.sendCmd(CmdEnum.Btn_VOZDER);
    Context.votingChoice = "Вы «воздержались»";
    setChoise("ВОЗДЕРЖАЛСЯ");
    setSelectZa(notselectCss);
    setSelectVozd(selectCssVozd);
    setSelectProtiv(notselectCss);
  }

  const [voting, setVoting] = React.useState({})
  // const process = getURLVarArr().process;

  React.useEffect(() => {
      getDataJson(server.api+"/voting_res.php", setVoting);
      Context.votingChoice = "Вы «не голосовали» ";
      Context.setShowVoteButtons = setShowVoteButtons;
    }, []);

  React.useEffect(() => {
      setShowVoteButtons(Context.delegat.IsVoteRight==1);
/*
      //Удалить после тестирования!!!!
      let  micN = Number(Context.confServer._micNum);
      let  time = Context.votingTimeLine*1000 ;

      if (micN > 60) {
        time = time + 900;
      } 

      setTimeout(() => {
        console.log("handle Za -----------------------------");
        handleSubmitZa({})
      },time)
    //Удалить после тестирования!!!!
*/    
    
    }, [Context.delegat.IsVoteRight]);

    React.useEffect(() => {
      if (voteTestTimeSec < 0 || voteTestTimeSec > Context.votingTimeLine)
        return;

      let maxTimeMsc = Number(voteTestTimeSec) * 1000;
      let timeMsc = Math.floor(Math.random() * (maxTimeMsc + 1))
      ;

      setTimeout(() => {

          console.log("handle Za -----------------------------");
          handleSubmitZa({})                           
        }, timeMsc)                            

    }, [voteTestTimeSec]);

  return ( 
  <Grid className={votingStyles.mainContainer}>
    <Grid container direction="column" className={votingStyles.voringContainer}> 
      {/* <Grid item sx={{background:""}}>
              <Typography variant="h5" textAlign="center" sx={{pt: 5}}>
                  Голосование по вопросу
              </Typography>
      </Grid> */}
        <Grid item>
            <Typography  textAlign="center" className={votingStyles.votingCaption}>
            {voting.Caption}
            </Typography>
        </Grid>

      
        <Grid item >
          <Grid item className={votingStyles.processCaption}>
              {/* <Typography variant="h4" textAlign="center" sx={{pt: 5, pl:10,pr:10 ,pb:3}}> */}
              <div>
                  Идет голосование
              </div>    
              {/* </Typography> */}
          </Grid>        
          <Grid item className={votingStyles.linearWithValueLabelContainer}>
              <LinearWithValueLabel/>
          </Grid>

          <Grid item >
              <Typography className={votingStyles.votingNote}>
              {voting.Note}
              </Typography>
          </Grid>

          <Grid item >
              <Typography className={votingStyles.choiseCaption}>
                  Ваш выбор:<span className={votingStyles.choiseSpan}>&nbsp;{choise}&nbsp;</span> 
              </Typography>
          </Grid>
          
          { (cardRegistered & userIsVoteRight) &&
              <Grid item container className={votingStyles.choisesContainer}>
                <ChoiseElement
                  caption="За"
                  onClickFunct={handleSubmitZa}
                  select={selectZa}
                  className={votingStyles.buttonZa}/> 
              
                <ChoiseElement
                  caption="Воздержался"
                  onClickFunct={handleSubmitVozder}
                  select={selectVozd}
                  className={votingStyles.buttonVozd}
                /> 

                <ChoiseElement
                  caption="Против"
                  onClickFunct={handleSubmitProtiv}
                  select={selectProtiv}
                  className={votingStyles.buttonProtiv}
                /> 
              </Grid>
          }
          { cardRegistered  &&
          <Grid item >
              <Typography className={votingStyles.lastChoiseCaption}>
                  Голосование по последнему нажатию
              </Typography>
          </Grid>
          }
      </Grid>
      
    </Grid>
  </Grid>

  )}