let externalList = ["zakon.udmgossovet.ru","localhost1"]

export const server = {
    ip: document.location.hostname,
    ipStatem: document.location.hostname,
    api: document.location.origin+"/api/",
    port: 10501,
    ws: "wss://"+document.location.hostname,
    debug: false,
    internet: externalList.includes(document.location.hostname)
};

if (server.internet) {
    server.ws = "wss://"+document.location.hostname;
} else {
    server.ws = "ws://"+document.location.hostname;
    server.port = 10511;
	// server.ws = "ws://192.168.20.251";	
}

if (document.location.hostname == "localhost") {
    server.api = "http://localhost:3010"+"/api/";
    server.ws  = "ws://localhost";
};
