import { now } from 'lodash';
import ReactDOM from 'react-dom/client';
// import { server } from './settings';
import { ConfServer } from './ConfDriver';
import { server } from "./settings";
import Modal from "@mui/material/Modal";
import getDataJson from './common/getDataJson';
import { useContextInfo } from './ContextInfo';
// import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
// import {BrowserRouter as Router,useRoutes} from 'react-router-dom';



export function FormatDate(d) {
  return  (d.toISOString().slice(0, 10))
}


const confServer = new ConfServer(server.ws,server.internet,server.port);
const confServerOrder = new ConfServer(server.ws,server.internet,server.port+1);
getDataJson(server.api+"/info.php",initConfServer);

function initConfServer(json) {
  console.log("initConfServer",json.idSeat);
  confServer.init(json.idSeat);
  confServerOrder.init(json.idSeat);
  // confStatemServer.init(json.idSeat);
}

confServer.on('INIT', (inited) => {
  if (inited) {
    // confServer.sendCmd("rfid_get");
  } 

  console.log('server status = ' + (inited ? 'on' : 'off'));
});

confServer.on('MIC', (cmd) => {
  
});


export const Context = {
   title: "start" ,
   loginUser : "",
   isLogin : false,
   token: "",
   access_token: "",
   server : server,
   status : null,
   votingTimeLine : 33,
   sesionCode : "",
   sesionCaption : "",
   delegat :{
    CardCode: "",
    Registered : 0,
    CardRegistered: 0,
    IsVoteRight : 0
   },
   onUpdateFio : null,
   loadURL : null,
   interfaceType : 1,
   confServer: confServer,
   confServerOrder: confServerOrder,
   votingChoice: "",
  //  confStatemServer : confStatemServer,
   user: {
      fio: "demo"
   },
   statement: {
    "idDelegate": "",
    "LastName": "",
    "FirstName": "",
    "SecondName": "",
    "idType": "",
    "State": "",
    "Title": ""
}
}
