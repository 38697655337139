import { createRoot } from 'react-dom/client';
import App  from './App';
import reportWebVitals from './reportWebVitals';
import ContextInfoProvider from './ContextInfo';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ContextInfoProvider>
        <App tab='home' />
    </ContextInfoProvider>
    
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
