import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { server } from '../settings';
import VcsMain from './VcsMain';
import vcsStyles from './Vcs.module.css'
import aboutStyles from './About.module.css';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
// }));

export default function About(props) {
  return <Grid container className={aboutStyles.mainContainer}>
     <div>
       <VcsMain></VcsMain>
     </div>
  </Grid>
  
//   <Grid container className={aboutStyles.mainContainer}>
//   <Grid item xs={10}>
//     <Paper className={aboutStyles.captionStyle}>Добро пожаловать на гостевую страницу сервера видеоконференций<br/>
//        {server.title}</Paper>

//   </Grid>

// </Grid>
  
}