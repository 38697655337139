import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DocTree from '../components/DocTree';
import PeopleList from '../components/PeopleList';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import {accountList,acl1,acl2,acl3,acl4,infoStatic} from '../_mock/account';
import PeopleListStack from '../components/PeopleListStack';
import LinearWithValueLabel from '../components/LinearWithValueLabel';
import getDataJson from "../common/getDataJson";
import { server } from '../settings';
import { Context } from '../Context';
import styles from './VotingRes.module.css';
import { useEffect } from 'react';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ItemVoting = (props) => {
 return  <Grid  container xs="auto"  direction="column" item   
          sx={{background:props.color,borderRadius:'2vmin'}}
          justifyContent="space-evenly"
          alignItems="center"
          >
           
            <Grid item sx={{width:"24vmin"}}>
              <Typography sx={{fontSize:"2.4vmin",pt:"1vmin"}}>
                    {props.title}
              </Typography>
            </Grid>
            <Grid item >
              <Typography sx={{fontSize:"11vmin"}}>
                    {props.val}
              </Typography>
            </Grid>
        </Grid>
}


export default function VotingRes() {

  const [caption, setCaption] = React.useState('');
  const [za, setZa] = React.useState('');
  const [protiv, setProtiv] = React.useState('');
  const [vozd, setVozd] = React.useState('');
  const [notVoted, setNotVoted] = React.useState('');
  const [resultCode, setResultCode] = React.useState('');
  // const [getData, setGoeData]

  // const [votingRes, setVotingRes] = React.useState({}) // оставили для caption от вопроса
  // const convertResult = (json) => {
  //   console.log("json", json);
  //   if (!json["otmena"]) {
  //     json["Result"] = "Решение "+json["Result"].toLowerCase();
  //   }
  //   setVotingRes(json);
  // }

  function updateVoteMode(json) {
    // console.log("updateVoteMode",json,json.length,json.length == 1);
    if (json.length == 1) {
      // console.log("updateVoteMode1",json[0]["VoteMode"]);
      setCaption(json[0]["Caption"]);
      setZa(json[0]["Za"]);
      setProtiv(json[0]["Protiv"]);
      setVozd(json[0]["Vozd"]);
      setNotVoted(json[0]["NotVoted"]);
      setResultCode(json[0]["ResultCode"] == "Parlament_Accept" ? "Решение принято" : "Решение не принято");
    }
  }

  // React.useEffect(() => {
  //     getDataJson(server.api+"/voting_res.php", convertResult);
  //   }, []);

  useEffect(() => {
    let url = server.api + "delegates.php?result=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
  }, []);
  
  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?result=1"
      console.log("updateVoteMode", url);
      getDataJson(url, updateVoteMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });

  return ( 
  <Grid container direction="column"  sx={{userSelect: "none",height:"100vh", bgcolor:"#EFF4F9"}}> 
    <Grid item>
            <Typography  sx={{p:3,fontSize:"6vmin"}}>
                Результаты голосования по вопросу
            </Typography>
            
    </Grid>
    <Grid item sx={{height:"22vmin"}}>
            <Typography  sx={{p:5,pb:0,pt:0, fontSize:"3.5vmin", height:"19vmin", overflow:"hidden", textOverflow:"ellipsis", display:"-webkit-box", WebkitLineClamp:"4"}}>
              {caption.replace(/"/g, '')}
            </Typography>
    </Grid>
    {/* <Grid item >
             <Typography variant="h5" sx={{pb:0}}>
                {votingRes.Note}
            </Typography>
        </Grid> */}
    <Grid item >
        
       
       <Grid 
        container 
        direction="row"
        spacing={0}
        sx={{color:"white"}}
        // xs={16}

          justifyContent="space-evenly"
          alignItems="center"
        >
        <ItemVoting title="За" color="#5FA65A" val={za} ></ItemVoting>
        <ItemVoting title="Против" color="#F14E50" val={protiv} ></ItemVoting>
        <ItemVoting title="Воздержались" color="#E7C511" val={vozd} ></ItemVoting> 
        <ItemVoting title="Не голосовали" color="#8c8c8c" val={notVoted} ></ItemVoting>
        </Grid>

        <Grid container direction="column" sx={{mt:4}}>
              <Grid   item xs={6} align='center'>
                <Typography  textAlign="center" sx={{ fontSize:"6vmin"}}>
                      {Context.votingChoice} 
                  </Typography>
              </Grid>
              <Grid   item xs={6}  align='center'>
                <Typography  textAlign="center" sx={{pt:10, fontSize:"10vmin", fontWeight:'bold'}}>
                      {resultCode}
                  </Typography>
              </Grid>
          </Grid>
      </Grid>
    </Grid>
  )}