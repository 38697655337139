import React, { useEffect, useContext ,useState} from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo.js";
import { Grid } from "@mui/material";
import styles from "./ScreenHall.module.css";
import ScreenPresPrep from "./ScreenPresPrep.js"
import ScreenPres from "./ScreenMainPage.js";
import { server } from "../settings.js";
import {getDataJson , getDataJsonWoOldval} from "../common/getDataJson.js";
import ScreenVotingPrepareDark from "./ScreenVotingPrepareDark.js";
import ScreenVotingProcessDark from "./ScreenVotingProcessDark.js";
import ScreenPresentationDark from './ScreenPresentationDark.js';
import ScreenVotingResDark from './ScreenVotingResDark.js';
import ScreenMainPageDark from './ScreenMainPageDark.js';
import ScreenCountVoicesDark from './ScreenCountVoicesDark.js';
import ScreenRegProcessDark from './ScreenRegProcessDark.js';
import ScreenRegResDark from './ScreenRegResDark.js';
import ScreenRegPrepareDark from './ScreenRegPrepareDark.js';
import ScreenVotingPrepareLarge from "./ScreenVotingPrepareLarge.js";
import ScreenVotingProcessLarge from "./ScreenVotingProcessLarge.js";
import ScreenPresentationLarge from './ScreenPresentationLarge.js';
import ScreenVotingResLarge from './ScreenVotingResLarge.js';
import ScreenMainPageLarge from './ScreenMainPageLarge.js';
import ScreenCountVoicesLarge from './ScreenCountVoicesLarge.js';
import ScreenRegProcessLarge from './ScreenRegProcessLarge.js';
import ScreenRegResLarge from './ScreenRegResLarge.js';
import ScreenRegPrepareLarge from './ScreenRegPrepareLarge.js';
export default function ScreenPredsedatel(props) {
// https://zakon.udmgossovet.ru/api/delegates.php?state=1
const [state, setState] = useState("0");
const [stateRegMode, setStateRegMode] = useState("0");
const [statem, setStatem] = useState({});


function UpdateData(json) {
  //console.log("json=", json)
  setState(json[0]["VoteMode"])
  setStateRegMode(json[0]["RegMode"])
}

function UpdateDataStatem(json) {
  console.log("UpdateDataStatem", json);
  if (json.length == 1) { 
    setStatem(json[0])
  } else {
    setStatem({})
  }
  console.log("json=", json, statem)
}

useEffect(() => {
  let url = server.api + "delegates.php?state=1&t="
  getDataJson(url, UpdateData)
}, []);

useEffect(() => {
  let url = server.api + "delegates.php?statem=1&t="
  getDataJsonWoOldval(url, UpdateDataStatem)
}, []);

useEffect(() => {
  const id = setInterval(() => {
    var seconds = new Date().getTime() / 1000;
      getDataJson(server.api+"/delegates.php?state=1&t="+seconds, UpdateData);
      getDataJsonWoOldval(server.api+"/delegates.php?statem=1&t="+seconds, UpdateDataStatem);
      
  }, 1000);
  return () => {
    clearInterval(id);
  };
}, []);

  return (
      // <Grid container className={styles.mainContainer}>
      //   {/* <DialogModal show={(regWindow & userIsVoteRight)}/> */}
      //   <Grid item xs={12} className={styles.right}>
      //   {( (state == "0") && (stateRegMode == "0"))  && <ScreenMainPageDark statem={statem}/>}
      //   { ((state == "0")  &&  (stateRegMode == "1") )&& <ScreenRegPrepareDark />}
      //   { ((state == "0")  &&  (stateRegMode == "2") )&& <ScreenRegProcessDark />}
      //   { ((state == "0")  &&  (stateRegMode == "3") )&& <ScreenRegResDark />}
      //   {( (state == "0") && (stateRegMode == "5"))  && <ScreenMainPageDark statem={statem}/>}
      //     { state == "1" && <ScreenVotingPrepareDark />}
      //     { state == "2" && <ScreenVotingProcessDark />}
      //     { state == "3" && <ScreenCountVoicesDark />}
      //     { state == "4" && <ScreenVotingResDark />}
      //     { state == "5" && <ScreenMainPageDark statem={statem} />}
      //   </Grid>
      // </Grid>
      <Grid container className={styles.mainContainer}>
        {/* <DialogModal show={(regWindow & userIsVoteRight)}/> */}
        <Grid item xs={12} className={styles.right}>
        {( (state == "0") && (stateRegMode == "0"))  && <ScreenMainPageLarge statem={statem}/>}
        { ((state == "0")  &&  (stateRegMode == "1") )&& <ScreenRegPrepareLarge />}
        { ((state == "0")  &&  (stateRegMode == "2") )&& <ScreenRegProcessLarge />}
        { ((state == "0")  &&  (stateRegMode == "3") )&& <ScreenRegResLarge />}
        {( (state == "0") && (stateRegMode == "5"))  && <ScreenMainPageLarge statem={statem}/>}
          { state == "1" && <ScreenVotingPrepareLarge />}
          { state == "2" && <ScreenVotingProcessLarge />}
          { state == "3" && <ScreenCountVoicesLarge />}
          { state == "4" && <ScreenVotingResLarge />}
          { state == "5" && <ScreenMainPageLarge statem={statem} />}
        </Grid>
      </Grid>
  );
}
