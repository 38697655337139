import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MD5 from "crypto-js/md5";
// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
// import { Context, FormatDate } from "src/Context";
// import { ApiServer } from 'src/settings';
//  import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { Context } from '../../Context';
import { ContextInfo,useContextInfo } from '../../ContextInfo';
import { server } from "./../../settings";
import styles from "./LoginForm.module.css";

export function setCookie(name, value, options = {}) { //in js

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}


export function deleteCookie(name) { //in js
  setCookie(name, "", {
    'max-age': -1
  })
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// ----------------------------------------------------------------------
async function loginUser(credentials) {

  //  console.log('credentials=', credentials);
  console.log('postdata=', JSON.stringify(credentials));
  const url = server.api + '/login.php';
  console.log('url=', url);
  console.log("credentials",credentials);
  // console.log('Context.token=', Context.token);
  return fetch(url, {
    method: 'POST',
    // credentials: "include",
    headers: {
     'Content-Type': 'application/json'
    },
    headers: {
      Cookie: 'token='+Context.token
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary
}));


export default function LoginForm({setUserStatus}) {
  const { userIsLogining , setUserIsLogining,setVcsUrl} = useContextInfo();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [caption,setCaption] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  
  const handleSubmit = async e => {
    e.preventDefault();
    let logintoken = MD5(username.toLowerCase().trim()+":"+password.trim()).toString();
    const logindata = await loginUser({
      username,
      password,
      logintoken
    });
    // let logindata = {};
    //*  console.log("token=", token);
    //*  setToken(token);
    // let nextPath = (logindata.air_status == 2) ?  '/dashboard/map' : '/user?users_id='+ logindata.users_id;

    let nextPath = (logindata.air_status == 2) ?  '/dashboard/map' : '/count';

    //  navigate('/user?users_id='+ logindata.users_id, { replace: true });
    // navigate(nextPath, { replace: true });
    console.log("Я получил статус", logindata);
    Context.token = logindata.token
    
    setCookie('voting', logindata.token, { path: '/' });
    setCookie('token', "demo12", { path: '/' });
    // setUserIsLogining(logindata.login == "1");
    if (logindata.login == "1") {
      setCookie('access_token', logindata.access_token, { path: '/' });
      setCookie('login', "1", { path: '/' });
      Context.access_token = logindata.access_token;
      // Context.isLogin = true;
      console.log("setUserIsLogining true",logindata.access_token,logindata.web);
      setVcsUrl(logindata.web);
      setCookie('vcs_url', logindata.web, { path: '/' });
      setUserIsLogining(true);
      navigate("/doc", { replace: true });
    } else {
      deleteCookie('vcs_url');
      deleteCookie('access_token');
      setCookie('access_token', "", { path: '/' });
      setCookie('login', "0", { path: '/' });
      Context.access_token = "";
      setUserIsLogining(false)
      setCaption("Неверный логин или пароль!")
      // 
    }
    // setUserStatus(logindata.air_status);
  }

  return (
  // <Grid 
  //         container 
  //         className={styles.pageStyle}
  //         spacing={2} 
  //           justifyContent="center"
  //           alignItems="center"
  //           sx={{pt:10}}
  //           xs={12}
  //         >
    <Grid xs={9}>
      
      {/* <Item sx={{background: '', borderRadius: 10}}> */}
      <Paper className={styles.mainContainer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.entrePanel}>
              <div className={styles.entreTextContainer}>
                <img src='/static/logo.png' className={styles.logoStyle}></img>
                <div className={styles.entreText}>
                  
                  Государственный Совет <br />Удмуртской 
                  Республики
                </div>
              </div>
              <div className={styles.entreLogContainer}>
                {/* <div className="login-field"> */}
                <div className={styles.fieldContainer}>
                  {/* <TextField fullWidth */}
                  <TextField color='info' className={styles.field} name="Имя пользователя" label="Логин" onChange={e => setUserName(e.target.value)} />
                </div>
                {/* <div className="login-field"> */}
                <div className={styles.fieldContainer}>
                  <TextField 
                    color='info'
                    className={styles.field}
                    name="password"
                    label="Пароль"
                    type={showPassword ? 'text' : 'password'}
                    onChange={e => setPassword(e.target.value)}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment  position="end">
                    //       <IconButton onClick={() => setShowPassword(!showPassword)} edge="end"> 
                    //         {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                    //         </IconButton> 
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* <div className={styles.captionError}>{caption}</div> */}
                </div>
                <div className={styles.captionError}>{caption}</div>
                <div className={styles.entreButtonContainer}>
                  <Button className={styles.entreButton} color='secondary' type="submit" variant="contained">
                    Вход
                  </Button>
              
                </div>
              </div>
            </div>  
          </form>
      </Paper>
      {/* </Item> */}
    </Grid>
  
  // </Grid>
    
  )
}

LoginForm.propTypes = {
  // setUserStatus: PropTypes.func.isRequired
};
