import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./ScreenVotingPrepareLarge.module.css";
import ScreenDateLarge from '../components/ScreenDateLarge';
import ScreenTimeLarge from '../components/ScreenTimeLarge';


export default function ScreenVotingPrepareLarge(props) {
  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  // const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  return (
      <Grid container className={styles.prepContainer} item>
        <Grid item className={styles.name}>
          Государственный Совет Удмуртской Республики
        </Grid>
        <Grid container className={styles.header}>
          <div >Внимание!</div>
          <div >Приготовиться к голосованию</div>
        </Grid>
        <div className={styles.bottomRowContainer}>
          <Grid className={styles.bottomRow}>
            <ScreenDateLarge />
            <ScreenTimeLarge />
          </Grid>
        </div>
      </Grid>
  );
}
