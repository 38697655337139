import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
  json,
  // date,
} from "react-router-dom";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./ScreenVotingRes.module.css";

const RowVoting = (props) => {
  let styleCircle = styles.circus;
  if (props.color === 'green') {styleCircle = styles.circus_green;}
  else if (props.color === 'red') {styleCircle = styles.circus_red;}
  else if (props.color === 'yellow') {styleCircle = styles.circus_yellow;}

  return (
    <Grid container 
    className={styles.resultsContainer}
    // direction="row" 
    // spacing={3}
    >
      <Grid item xs className={styles.circusCont} > <div className={styleCircle} > </div></Grid>
      <Grid item xs={6} className={styles.votingCaption} >
        {props.text}
      </Grid>
      <Grid item xs className={styles.votingNumber}>
        {props.votingRes}
      </Grid>
    </Grid>
  );
};

function FontSize(props) {
  const navigate = useNavigate();
  // const [fio, setFio] =  React.useState([""]);
  // const [userInfo,setContext]  = useContext(ContextInfo);
  const { user, updateUser, cardRegistered } = useContextInfo();
  // Context.onUpdateFio = setFio;

  useEffect(() => {
    // setFio(Context.votingTimeLine)
    console.log("FontSize update", cardRegistered);
    if (cardRegistered) {
      navigate("/doc", { replace: true });
    }
  }, [cardRegistered]);
}
export default function ScreenVotingRes(props) {
  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  // const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const [caption, setCaption] = React.useState('');
  const [za, setZa] = React.useState('');
  const [protiv, setProtiv] = React.useState('');
  const [vozd, setVozd] = React.useState('');
  const [resultCode, setResultCode] = React.useState('');


  const {
    
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  function updateVoteMode(json) {
    // console.log("updateVoteMode",json,json.length,json.length == 1);
    if (json.length == 1) {
      // console.log("updateVoteMode1",json[0]["VoteMode"]);
      setCaption(json[0]["Caption"]);
      setZa(json[0]["Za"]);
      setProtiv(json[0]["Protiv"]);
      setVozd(json[0]["Vozd"]);
      setResultCode(json[0]["ResultCode"] == "Parlament_Accept" ? "Решение принято" : "Решение не принято");
    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?result=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
  }, []);
  
  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?result=1"
      console.log("updateVoteMode", url);
      getDataJson(url, updateVoteMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });

  return (

    <Grid container className={styles.mainContainer}>
      <Grid item className={styles.name}>Государственный Совет Удмуртской Республики</Grid>
      {/* <div className={styles.question} >«{userOrderInfo.votingres && userOrderInfo.votingres.Caption}»</div> */}
      {/* <Grid item className={styles.question} >{caption.replace(/"/g, '')}</Grid> */}
      {/* <Grid item className={styles.question} >{caption.replace(/"/g, '').trim()}</Grid> */}
      <Grid container className={styles.centerContainer}>
        <Grid item className={styles.header} >Итоги голосования</Grid>
        <Grid item container className={styles.results}>
          <RowVoting text="За" votingRes={za} color='green' id="za" />
          <RowVoting text="Против" votingRes={protiv} color='red' id="protiv" />
          <RowVoting text="Воздержались" votingRes={vozd} color='yellow' id="vozd" />
          <Grid item className={styles.line}></Grid>
        
          {/* <RowVoting text="Голосовали" votingRes={parseInt(za) + parseInt(protiv) + parseInt(vozd)} /> */}
          
          <RowVoting text="Голосовали" votingRes={isNaN(parseInt(za) + parseInt(protiv) + parseInt(vozd)) ? " " : (parseInt(za) + parseInt(protiv) + parseInt(vozd))} />      
        </Grid>
      </Grid>
      <Grid container className={styles.decisionContainer}>
        <Grid item className={styles.decision} >
        {resultCode != "" ?
          <div >{resultCode}</div>
          :
          <div >Идет подсчет голосов</div>
        }
        </Grid>
      </Grid>
    </Grid>

  );
}
