import React, { useEffect, useContext ,useState} from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo.js";
import { Grid } from "@mui/material";
import ScreenPresPrep from "./ScreenPresPrep.js"
import ScreenPres from "./ScreenMainPage.js";
import { server } from "../settings.js";
import {getDataJson , getDataJsonWoOldval} from "../common/getDataJson.js";
import ScreenVotingPrepare from "./ScreenVotingPrepare.js";
import ScreenVotingProcess from "./ScreenVotingProcess.js";
import ScreenPresentation from './ScreenPresentation.js';
import ScreenVotingRes from './ScreenVotingRes.js';
import ScreenMainPage from './ScreenMainPage.js';
import ScreenCountVoices from './ScreenCountVoices.js';
import ScreenRegProcess from './ScreenRegProcess.js';
import ScreenRegRes from './ScreenRegRes.js';
import ScreenRegPrepare from './ScreenRegPrepare.js';
// import styles from './ScreenPredsedatel.module.css';


export default function ScreenPredsedatel(props) {
// https://zakon.udmgossovet.ru/api/delegates.php?state=1
const [state, setState] = useState("0");
const [stateRegMode, setStateRegMode] = useState("0");
const [statem, setStatem] = useState({});

function UpdateData(json) {
  //console.log("json=", json)
  setState(json[0]["VoteMode"])
  setStateRegMode(json[0]["RegMode"])
}

function UpdateDataStatem(json) {
  console.log("UpdateDataStatem", json);
  if (json.length == 1) { 
    setStatem(json[0])
  } else {
    setStatem({})
  }
  console.log("json=", json, statem)
}

useEffect(() => {
  let url = server.api + "/delegates.php?state=1&t="
  getDataJson(url, UpdateData)
}, []);

useEffect(() => {
  let url = server.api + "/delegates.php?statem=1&t="
  getDataJsonWoOldval(url, UpdateDataStatem)
}, []);

useEffect(() => {
  const id = setInterval(() => {
      var seconds = new Date().getTime() / 1000;
      getDataJson(server.api+"/delegates.php?state=1&t="+seconds, UpdateData);
      getDataJsonWoOldval(server.api+"/delegates.php?statem=1&t="+seconds, UpdateDataStatem);
      
  }, 1000);
  return () => {
    clearInterval(id);
  };
}, []);

  return (

    <Grid container >
      {/* <DialogModal show={(regWindow & userIsVoteRight)}/> */}
      <Grid item xs={4} >
        <ScreenPresPrep statem={statem}/>
      </Grid>
      {/* <Grid item xs={8}>
        { state == "0" && <ScreenMainPage statem={statem}/>}
      </Grid> */}
      <Grid item xs={8} >
        {/* { state == "0" && <ScreenMainPage />} */}
        {( (state == "0") && (stateRegMode == "0"))  && <ScreenMainPage statem={statem}/>}
        { ((state == "0")  &&  (stateRegMode == "1") )&& <ScreenRegPrepare />}
        { ((state == "0")  &&  (stateRegMode == "2") )&& <ScreenRegProcess />}
        { ((state == "0")  &&  (stateRegMode == "3") )&& <ScreenRegRes />}
        {( (state == "0") && (stateRegMode == "5"))  && <ScreenMainPage statem={statem}/>}
        { state == "1" && <ScreenVotingPrepare />}
        { state == "2" && <ScreenVotingProcess />}
        { state == "3" && <ScreenCountVoices />}
        { state == "4" && <ScreenVotingRes />}
        { state == "5" && <ScreenMainPage statem={statem}/>}
      </Grid>
    </Grid>

  );
}
