import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./ScreenMainPageDark.module.css";
import ScreenDateDark from '../components/ScreenDateDark';
import ScreenTimeDark from '../components/ScreenTimeDark';
import ScreenPresentationDark from './ScreenPresentationDark.js';

export default function ScreenMainPageDark({statem}) {
    console.log("ScreenMainPageDark statem", statem);

  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  // const [userInfo, setContext] = React.useState("Иванов Иван Иванович");
  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  return (
    <>
    { statem["idDelegate"] ? <> <ScreenPresentationDark />
     {/* { statem["idDelegate"] ? <> <Grid container className={styles.mainContainer} item>
            <img src='/static/logo.png' className={styles.logoStyle}></img>
            <Grid item className={styles.question}>{statem.Theme}</Grid>
            <Grid item className={styles.header}>Выступает</Grid>
            <Grid item className={styles.fio}>{statem.LastName} {statem.FirstName} {statem.SecondName}</Grid>
          </Grid>
          <Grid container className={styles.bottomRow}>
              <ScreenDate />
              <ScreenTime />
          </Grid> */}
      </> : <> <Grid container className={styles.mainContainer} >
            <img src='/static/logo.png' className={styles.logoStyle}></img>
            <Grid item className={styles.header}>
              <div>Государственный Совет <br/> Удмуртской Республики</div>
              <div>Удмурт Элькунлэн <br/> Кун Кенешез</div>
            </Grid>
            <div className={styles.bottomRowContainer}>
            <Grid item className={styles.bottomRow}>
                <ScreenDateDark />
                <ScreenTimeDark />
            </Grid>
          </div>
          </Grid>
          
      </> 
    } 
    {/* { statem["idDelegate"] ? <> <ScreenPresentationDark />

    {statem["idDelegate"]}  
      </> : <> <Grid container className={styles.mainContainer} item>
        <img src='/static/logo.png' className={styles.logoStyle}></img>
        <Grid item className={styles.header}>
          <div>Государственный Совет <br/> Удмуртской Республики</div>
          <div>Удмурт Элькунлэн <br/> Кун Кенешез</div>
        </Grid>
      </Grid>
      <Grid container className={styles.bottomRow}>
          <ScreenDateDark />
          <ScreenTimeDark />
      </Grid>
      </>
    } */}
    </>
  );
}
