import EventEmitter from 'events'
// import { useContextInfo } from './ContextInfo';

const STREAM_SERVER_PORT = 10501;
const CONNECTION_TIMEOUT_MSC = 5000;

const WEB_PREFIX = "web:";
const WEB_INIT_STRING = "web:init";

export class CmdEnum {
    static On = "on";
    static Off = "off";
    static Unlog = "_rfid_0";
    static Apply = 'apply';
    static Unapply = 'unapply';
    static Btn_ZA = 'B';
    static Btn_VOZDER = 'C';
    static Btn_PROTIV = 'D';
    static Btn_REGISTER = 'R';
}

export class ConfServer extends EventEmitter {
    constructor(srvHost, external = false , port = STREAM_SERVER_PORT) {
        super();
        // const { user, updateUser } = useContextInfo();
        this._micNum = 0;
        this._rfid = "";
        this._micPrefix = external ? "web" : "mic";
        this._inited = false;
        this._connected = false;
        this._socketUrl = srvHost + ":" + port;
        this._cmdSocket = null; //new WebSocket("ws://localhost");
        this.showRegWindow = false;
    }

    sendMicNum() {
        console.log("sendMicNum",this._micNum,this._cmdSocket);
        let cmd = WEB_INIT_STRING + "_" + this._micNum;
        if (this._cmdSocket?.readyState == 1) {
            this._cmdSocket?.send(cmd);
            cmd = WEB_PREFIX+this._micPrefix+"_"+this._micNum+"_ver_1";
            this._cmdSocket?.send(cmd);

            //Alex control
            cmd = WEB_PREFIX+this._micPrefix+"_"+this._micNum+"_rfid_"+this._rfid;
            this._cmdSocket?.send(cmd); 
        } else {
            console.log('%c sendMicNum _cmdSocket not connected! ', 'background: #222; color: #bada55',this._cmdSocket);    

        }
    }

    updateMicNum(micNum,rfid) {
        if ((micNum != this._micNum) || (rfid != this._rfid))  {
            this._rfid = rfid;
            this._micNum = micNum;
            this.sendMicNum();
        }
    }

    init(micNum) {

        if (this._inited) {
            return;
        }

        if (micNum !== undefined) {
            this._micNum = Number(micNum);
        }

        this._cmdSocket = new WebSocket(this._socketUrl);

        this._cmdSocket.onopen = async (event) => {
            console.log(`Connection to ${this._cmdSocket?.url} opened successfully.`);
            this.sendMicNum();
            
            this._inited = true;
            this.emit("INIT", this._inited);
        };

        this._cmdSocket.onerror = (error) => {
            console.log(`Websocket error with target = ${JSON.stringify(error.target?.url)}`);
        };

        this._cmdSocket.onclose = (event) => {
            console.log(`Websocket closed, reason = ${event.reason}, code = ${event.code}`);

            this._inited = false;

            // @ts-ignore
            this._cmdSocket = null;

            this.emit("INIT", this._inited);

            setTimeout(async () => {
                this.init();
            }, CONNECTION_TIMEOUT_MSC);

            return;
        };

        this._cmdSocket.onmessage = async (message) => {
            console.log('Message received: ' , message.data);

            const data = await message.data;
            console.log('Message received: ' + data);

            let cmd = {
                type: "",
                name: "",
                act: "",
                data: ""
            }

            var partsArray = data.split('_');
            cmd.type = partsArray[0].trim();

            if (cmd.type === 'mic') {
                var micNum = partsArray[1];
    
                if (this._micNum != Number(micNum)) return;

                cmd.name = partsArray[2].trim();
                cmd.act = "";
                cmd.data = partsArray[3].trim();

                console.log('Mic cmd received: ',cmd );
                this.emit('MIC', cmd);
            }

            if (cmd.type === 'cmd') {
                cmd.name = partsArray[1].trim();
                cmd.act = partsArray[2].trim();
                cmd.data = partsArray[3]?.trim();
    
                console.log('Cmd received: ',cmd );
                this.emit('CMD', cmd);
            }

        }
    }

    sendCmd(cmd) {
        if (!this._inited) {
            console.log('The conference server is not inited yet');
            return;
        }

        let str = WEB_PREFIX + this._micPrefix+"_" + this._micNum + "_" + cmd;
        this._cmdSocket?.send(str);
    }
}