//export const API_URL = "http://localhost:8000/api/";
export const API_URL = "/api/";
export const SERVER_URL = API_URL + "reports2/";
export const PLACES_URL = API_URL + "places/uploadPlaces.php";
export const CONVERT_URL = API_URL + "convert_by_link.php";
export const GET_CONVERT_STATUS_URL = API_URL + "convert_get_status.php";

export const GET_SESSIONS_URL = SERVER_URL + "api_report.php?act=get_sessions";
export const GET_DELEGATES_URL = SERVER_URL + "api_report.php?act=get_delegates";
export const GET_TASKS_URL = SERVER_URL + "api_report.php?act=get_tasks";

export const getReports = (type, session_id, delegate_id = "", question_id = "") => {
    console.log("Начинаю создавать отчеты");
    const sessionEndUrl = session_id ? "&session_id=" + session_id : ""; 
    const delegateEndUrl = (delegate_id != "") ? "&delegate_id=" + delegate_id : ""; 
    const questionEndUrl = (question_id != "") ? "&question_id=" + question_id : ""; 
    const url = SERVER_URL + "create_report.php?report_type=" + type + "&t=" + Date.now() + sessionEndUrl + delegateEndUrl + questionEndUrl;
    document.location.href = url;  
};

export async function getDataJson(url, callbackSuccess, callbackError = () => {}) {
    console.log("getDataJsonToken");
    const response = await fetch(url, {
      method: 'GET'
    });
    if (response.ok) {
      try {
        const json = await response.json();
        console.log("JSON", json);
        callbackSuccess(json);
      } catch (error) {
        console.log("Not json response", error);
        callbackError();
      };
    } else {
      callbackError();
    };
  }



