import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { createStyles, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import pageStyles from "./DocTree.module.css";


const TreeRowDoc = (props ) => {
  return (props.docs.map((value,i) => {
    let nodeId = props.parentkey+"_"+i.toString();
    // console.log("TreeRowDoc",props,nodeId);
    const onClick = () =>{
      console.log("TreeRowDoc",value.docId);
      props.onOpen(value.docId);
    } 
    return (
        <TreeItem sx={{pt:0}} className={pageStyles.treeItem} key={nodeId} nodeId={nodeId} label={value.text} onClick={onClick}>
        </TreeItem>
    )})
  )
};

export default function DocTree(props) {
  console.log("DocTree",props);
  let db =[];
  if (Array.isArray( props.docList)) {
    db = props.docList;
  }
  
  return (
    // <Box sx={{fontStyle:"bold",fontSize:"2vw",p:2}}>
    // Документы к очередной сессии ГС УР
    // </Box>
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      className={ pageStyles.treeView }
      // sx={{ height: "87vh", flexGrow: 1, overflowY: 'auto' }}
    >
     
      {db.map((value,i) => {
      let nodeId = "a"+i.toString();
      // console.log("nodeId==========",nodeId);
      if (value.docs.length > 0 ) {
          return (
              <TreeItem sx={{p:3}}  key={nodeId} nodeId={nodeId} className="treeLabel"  label={value.text}>
                <TreeRowDoc  parentkey={nodeId}  docs={value.docs} onOpen={props.onOpen}></TreeRowDoc>
              </TreeItem>
          )
        } else {
          return (<TreeItem sx={{p:3}}  key={nodeId} nodeId={nodeId} label={value.text}/>);
        }
    })}

    </TreeView>
  );
}