import React from 'react';
import { Box } from '@mui/material';
import { CircularProgress, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { CONVERT_URL, GET_CONVERT_STATUS_URL, getDataJson } from '../services/script';

function Progress() {
    const [convertLink, setConvertLink] = React.useState("");
    const [loadingStatus, setLoadingStatus] = React.useState(0); // ориентируемся только на loadingStatus

    const process = () => {setLoadingStatus(0.1)};
    const succeess = (json) => {setLoadingStatus(json.loadingStatus)};
    const error = () => {setLoadingStatus(0)};

    const getLoadingStatus = () => {
        getDataJson(GET_CONVERT_STATUS_URL, succeess, error);
    };

    React.useEffect(() => {

        const process = setInterval(() => {
            getLoadingStatus();
          }, 2000);
    
        return () => {
          clearInterval(process);
        };
      }, []);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '40px' }}>
                <TextField id="filled-basic" label="Введите ссылку" 
                //value="http://www.udmgossovet.ru/doc/7sozyvsess/9ses/Publ.zip" 
                variant="filled" sx={{ width: '600px' }} 
                onChange = {
                    (e) => {
                        setConvertLink(e.target.value);
                        console.log(e.target.value);
                    }
                }
                />
                <Button variant="contained" 
                disabled = {(loadingStatus > 0) ? true : false}
                onClick={() => { 
                    process();
                    getDataJson(CONVERT_URL + '?link=' + btoa(convertLink), succeess, error); 
                 }}
                 >конвертировать</Button>
            </div>
            <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: '20px' }}>
                {(loadingStatus > 0) ? "Конвертация запущена" : "Введите ссылку для конвертации"}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                <Box position="relative" display="inline-flex" >
                    <CircularProgress variant="determinate" thickness={2} size={300}
                        value={loadingStatus} />
                    <Box
                        bottom={0}
                        right={0}
                        top={0}
                        justifyContent="center"
                        left={0}
                        display="flex"
                        alignItems="center"
                        position="absolute"
                        
                    >
                        <h2>{`${Math.round(loadingStatus)}%`}</h2>

                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default Progress;