import * as React from 'react';
import { Viewer, SpecialZoomLevel,Worker } from '@react-pdf-viewer/core';
import { server } from '../settings';
import Paper from '@mui/material/Paper';
// import {getDataJson} from '../common/getDataJson';
import getDataJson from '../common/getDataJson';
import { getDataImage } from '../common/getDataJson';
// import ScrollToTop from "react-scroll-to-top";
import { useRef } from 'react';
import { Typography } from '@mui/material';
import {Card,CardContent} from '@mui/material';
import InlineSVG from 'svg-inline-react';
import SVG from 'react-inlinesvg';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import styles from "./PdfViewer.module.css";

const Page = (props) => {
 let url = server.api +"doc_svg.php?id="+props.url + "&file=" + props.files[0];

 //let url = "http://localhost:3010/api/doc_svg.php?id=/291892/page001.svg";
 const [srcData, setSrcData] =  React.useState("");
 const [showSpinner, setShowSpinner] =  React.useState(true);

 React.useEffect(() => {
    //setSrcData("");
    setShowSpinner(true)
    setTimeout(getDataImage, props.index*5000 + 100, url, setSrcData, setShowSpinner); 
    // getDataImage(url, setSrcData , setShowSpinner);

    // let src= 'data:image/svg+xml;utf8,';
    // const response = await fetch(url); 
     
    //    let blob = response.blob();
    //    src = src+ blob
 },[props.files]);


 
 return(
    <Paper sx={{m:2}} elevation={18} >
        {showSpinner ?
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height:"90vh" }}>
            <CircularProgress size={240}/>
        </Box> :
         <img className='documentView' src={'data:image/svg+xml;base64,' + srcData} />
        }   
       

    </Paper>
 )
}


export default function PdfViewer(props) {
    // const menuRef = useRef();
    const [files, setFiles] =  React.useState([]);
    const [defCaption, setDefCaption] =  React.useState("");
    const updatePage = (json) => {
        console.log("updatePage",json);
        console.log("json.length",json.length);
        setFiles(json);
    }

    
    // let url = server.api +"doc_pdf.php?id="+props.doc;

    function template(doc, defaults, content) {
        return content;
      }

      
    React.useEffect(() => {
        console.log("useEffect",props);
     },[props.sesionCode]);

    React.useEffect(() => {
        let url = server.api +"doc_svg_json.php?id="+props.doc;
        getDataJson(url,updatePage);

        // console.log("url 11",url,menuRef.current.parentElement);
        // menuRef.current.parentElement.scrollTo(0, 0);
        }, [props.doc]);

    return (
        <div  >
          {files.length >= 1 ?
            files.map((value , i) => {
                // return <Page url = {value + "&t=" + (new Date()).getTime()}/>
                return <Page url = {value} index={i} files={files}/>
            })
            : 
            <Card variant="outlined" sx={{height:"130vh"}} >
            {/* <Card variant="outlined" sx={{height:"130vh"}} > */}
            
            <CardContent  >
                <Grid container className={styles.headContainer} >
                    <img src='/static/logo.png' className={styles.logoStyle}></img>
                    <Grid container className={styles.header}>
                    <Typography sx={{fontSize:"6vmin"}}>Государственный Совет Удмуртской Республики</Typography>
                    <Typography sx={{fontSize:"6vmin"}}>Удмурт Элькунлэн Кун Кенешез</Typography>
                    </Grid>
                </Grid>
                <Typography sx={{mt:5, fontSize:30}} gutterBottom variant="h2" display="flex" justifyContent="center" component="div">
                        {props.sesionCode}
                </Typography>
                <Typography sx={{mt:5, textAlign:"center"}}variant="h3"  display="flex" justifyContent="center" >
                    {props.sesionCaption} 
                </Typography>
             </CardContent>

            
            </Card>

           

        }
        </div>
    )
}