import  React, {useEffect,useContext} from 'react';
import { ContextInfo } from "../ContextInfo";
import { Context } from "../Context";
import { server } from "../settings";
import getDataJson from "../common/getDataJson";
import {useNavigate } from 'react-router-dom';
import { useContextInfo } from '../ContextInfo';
import { Grid } from '@mui/material';
import timeStyle from './ScreenDate.module.css'


  function getRussianMonthWithDeclension(monthIndex) {
    var russianMonths = [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];
    
    var month = russianMonths[monthIndex];
    var declension = '';
    if (monthIndex === 7) {
        declension = 'августа';
    } else if (monthIndex === 2) {
        declension = 'марта';
    } else {
        declension = month.slice(0, -1) + "я";
    }

    return declension;
  }

  const modDateTime = (str) => {
    const dateString = str;
    const date1 = new Date(dateString);
    let year = "" + date1.getFullYear();
    const currDate = (date1.getDate() + " " + getRussianMonthWithDeclension(date1.getMonth()).toLowerCase() + " " + year.padStart() );

    return currDate;
  }

  export default function ScreenDate() {
    const [currentDate, setCurrentDate] = React.useState("");

  

  function updateVoteMode(json) {
  if (json.length == 1) {
    setCurrentDate(modDateTime(json[0]["currentTime"]));
  }
  }

  useEffect(() => {
      let url = server.api + "delegates.php?state=1"
      console.log("updateVoteMode", url);
      getDataJson(url, updateVoteMode)
  }, []);


  useEffect(() => {
  const timer = setInterval(() => {
    // setSeconds(seconds => seconds + 1);
    let url = server.api + "delegates.php?state=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
  }, 500);

  // очистка интервала
  return () => clearInterval(timer);
  });

  return (
    <>
      {(currentDate == "") ? <></> : <Grid className={timeStyle.date}> {currentDate} г.</Grid>}
    </>
  )
}