import  React, {useEffect, useState, useContext} from 'react';
import { ContextInfo } from "../ContextInfo";
import { Context } from "../Context";
import { server } from "../settings";
import getDataJson from "../common/getDataJson";
import {useNavigate } from 'react-router-dom';
import { useContextInfo } from '../ContextInfo';
import { Grid } from '@mui/material';
import CurrentTime from './CurrentTime';
import timeStyle from './ScreenTimeLarge.module.css';
import ReactDOM from 'react';
import { useTime } from 'react-timer-hook';

const modDateTime = (str) => {
      const dateString = str;
      const date1 = new Date(dateString);
      let minutes = "" + date1.getMinutes() ;
      let seconds = "" + date1.getSeconds();
      let currTime = (date1.getHours(2, '0') + ":" + minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0'));
      return currTime;
}

export default function ScreenTimeLarge() {
  const [currentTime, setCurrentTime] = React.useState();

  function updateVoteMode(json) {
    if (json.length == 1) {
      setCurrentTime(modDateTime(json[0]["currentTime"]));
    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?state=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
  }, []);
  
  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?state=1"
      console.log("updateVoteMode", url);
      getDataJson(url, updateVoteMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });

  return (
    <>
    {(currentTime == "") ? <></> : <Grid item className={timeStyle.time} > {currentTime}</Grid>}
    </>
  )
}

