import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import ButtonAppBar from '../components/ButtonAppBar';
import Timer from '../components/Timer';
import { useContextInfo } from '../ContextInfo';
import DialogModal from '../components/DialogModal';
import VotingModal from '../pages/VotingModal';
import { Context } from '../Context';

// components
// import Logo from '../components/Logo';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const { user,statusApp, regWindow,userIsLogining, voting,votingStep,cardRegistered } = useContextInfo();
  console.log("LogoOnlyLayout userIsLogining",userIsLogining, Context.access_token);
  return (
    <>
      <ButtonAppBar/>
        <Outlet />
      
        {/* <VotingModal show={voting & cardRegistered & userIsLogining} voteWindow={votingStep} />
        <DialogModal show={regWindow & cardRegistered & userIsLogining}/> */}
      
      <Timer/>
    </>
  );
}
