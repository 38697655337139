import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import { CircularProgress } from '@mui/material';
import { fontSize } from '@mui/system';
import getDataJson from "../common/getDataJson";
import { server } from '../settings';
import prepStyles from './VotingPrepare.module.css';


export default function VotingPrepare(props) {
    const [voting, setVoting] = React.useState({})
    // const process = getURLVarArr().process;
  
    React.useEffect(() => {
        getDataJson(server.api+"/voting_res.php", setVoting);
        
      }, []);
 return (
        <Grid  container 
          className={prepStyles.mainContainer}
          >
          <Grid  item >
              <Typography className={prepStyles.prepCaption}>
                  Идет подготовка к голосованию 
              </Typography>
              <Typography 
              // sx={{maxHeight:"100px", overflow:"hidden", textOverflow:"ellipsis", display:"-webkit-box", WebkitLineClamp:"3"}} 
              className={prepStyles.votingCaption}>
                {voting.Caption}
                {/* {voting.Caption.replace(/"/g, '')} */}
            </Typography>
          </Grid>

          {/* <Grid  item >
              <CircularProgress color="inherit" />
              
          </Grid> */}
          <Grid item className={prepStyles.downloadIconContainer}>
            <img src="./static/icon-download.svg" className={prepStyles.downloadIcon}>
            </img>
          </Grid>
           
        </Grid>

 )}
   