import * as React from 'react';
import { Navigate, useRoutes, Routes, Route,useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar } from '@mui/material';
import { useContextInfo } from '../ContextInfo';
import { Context } from '../Context';
import pageStyles from "./ButtonAppBar.module.css";
import { GrayButton } from './Button';

// const styles = StyleSheet.create({
//   customBtnBG: {
//     backgroundColor: "#007aff",
//   }
// })
export default function ButtonOrder() {
  const { userIsLogining , setUserIsLogining,statusTest, setStatusTest,userOrder,userOrderList, setUserOrderList, setUserOrder,userOrderInfo,
    currentPage, setCurrentPage} = useContextInfo();
  const [orderInfo, setOrderInfo] = React.useState({order:false, caption : "Записаться на выступление"});
  const [color, setColor] = React.useState("");
  
  const documentOrder = () => {
    console.log("documentOrder");
    // if (orderInfo.order) {
    if (orderInfo.order) {
      setOrderInfo({order: false, caption : ""})
      Context.confServerOrder.sendCmd("unapply");
      // setUserOrder(true);
      setUserOrderList(false);
    } else {
      Context.confServerOrder.sendCmd("apply");
      setOrderInfo({order: true , caption : ""});
      setUserOrder(true);
      setUserOrderList(true);
    }
    
    // navigate('/vcs', { replace: true });
  };
  const orderOff = () => {
    console.log("orderOff");

    Context.confServerOrder.sendCmd("off");
  }

  
  React.useEffect(() => {
    if (userOrderList ) {
      // setColor("red");
      // setColor("#E7C511");
      // setUserOrderList(false);
      setOrderInfo({order: true , caption : "Вы записаны на выступление"});
      
    } else {
      // setColor("green")
      // setColor("#E6E8E7")
      // setUserOrderList(true);
      setOrderInfo({order: false, caption : "Записаться на выступление"})
      // Наш вариант
      
    }
  }, [userOrderList]);

  React.useEffect(() => {
    setUserOrder(userOrderList);
  },[userOrderList])
  

  return (
   
          <>
          {/* {userOrderInfo &&
          userOrderInfo["statement"] &&
          userOrderInfo["statement"]["State"] == 2 ?  
             ( <Button size="large"  sx={{ backgroundColor: ""}}  variant="contained" onClick={orderOff} 
              className={
                pageStyles.button
              }
              >Завершить выступление </Button>) : (
                <Button  size="large" sx={{ height:"164px", width:"20%", m:"2px", backgroundColor: color, '&:hover': { backgroundColor: color }}}  variant="contained" onClick={documentOrder} 
              className={
                pageStyles.button
              }
              >{orderInfo.caption}</Button>
              )

          } */}

          {userOrderInfo &&
          userOrderInfo["statement"] &&
          userOrderInfo["statement"]["State"] == 2 ?  
             ( <Button className={pageStyles.orderOffPage} variant="contained" onClick={orderOff} 
              
              >Завершить выступление </Button>) : (
                
                <Button className={orderInfo.order ? pageStyles.orderOnPage : pageStyles.orderPage} variant="contained" onClick={documentOrder} 
          
              >{orderInfo.caption}</Button>
              )

          }
          
          </>
  )     
}