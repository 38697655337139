import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Context } from '../Context';
import votingStyles from '../pages/Voting.module.css';

function LinearProgressWithLabel(props) {
  
  let label = Math.round( props.valMax - props.value);
  let pos = Math.round(props.value/props.valMax * 100);
  if (pos >100) {pos = 100};
  if (label < 0) {label = 0};
  console.log("LinearProgressWithLabel",pos,props.value,Math.round(props.valMax - props.value));
  return (
    <Box className={votingStyles.linearMainContainer}>
      {/* <Box className={votingStyles.linearProgressContainer}> */}
        <LinearProgress variant="determinate" color='secondary' className={votingStyles.linearProgressStyle} value={pos} />
      {/* </Box>
      <Box> */}
        <Typography className={votingStyles.linearTimer}>{`${label}\u00A0сек`}</Typography>
      {/* </Box> */}
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel(props) {
  const fulltime = props.fulltime;
  const [progress, setProgress] = React.useState(0);
  const [currTime, setCurrTime] = React.useState(0);
  const [valMax, setvalMax] = React.useState(100);
  React.useEffect(() => {
    let t = Context.votingTimeLine;
    // let t =0;
    let step = 100/Context.votingTimeLine;
    console.log("votingTimeLine",Context.votingTimeLine);
    // prevProgress = 0;
    setvalMax()
    const timer = setInterval(() => {
      setProgress((prevProgress) =>{
        if (prevProgress == 7) {
          //Context.confServer.sendCmd("B");
        }
        return (prevProgress >= {t} ? t : prevProgress + 1)}
      );
     
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [Context.votingTimeLine]);

  return (
    <Box className={votingStyles.linearProgressContainer}>
      <LinearProgressWithLabel value={progress} valMax={Context.votingTimeLine}  />
    </Box>
  );
}
