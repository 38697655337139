import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import { Grid } from "@mui/material";
import styles from "./ScreenPresentationLarge.module.css";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import ScreenDateLarge from '../components/ScreenDateLarge';
import ScreenTimeLarge from "../components/ScreenTimeLarge";
import { FormatMinutesLeft } from "../common/getDataJson";


export default function ScreenPresentationLarge(props) {
  // console.log("STYLES=", styles);
  // const userName = React.useContext(ContextInfo)
  const [fio, setFio] = React.useState("");
  const [fulltime, setfulltime] = React.useState(0);
  const [lefttime, setleftTime] = React.useState(0);
  const [caption, setCaption] = React.useState('');
  const { colors, setColors } = useContextInfo();
  const { timeVoting, setTimeVoting } = useContextInfo();
  const [speechtype, setSpeechType] = React.useState('');

  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  // function formatSeconds(secs) {
  //   // var hours   = "" + Math.floor(secs / 3600);
  //   var minutes = "" + Math.floor((secs / 60) % 60);
  //   var seconds = "" + Math.floor(secs % 60);
  //   var formattedTime = '';

  //    formattedTime = (minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0')); 
  // return formattedTime;
  // }

//   function formatSeconds(seconds) {
//     var hours   = Math.floor(seconds / 3600);
//     var minutes = Math.floor((seconds - (hours * 3600)) / 60);
//     var formattedTime = '';

//     if (hours > 0) {
//         formattedTime += hours + "h ";
//     }

//     if (minutes > 0) {
//         formattedTime += minutes + ":";
//     }

//     formattedTime += (seconds - (hours * 3600) - (minutes * 60)) + "";

//     return formattedTime;
// }

function updateVoteMode(json) {
  // console.log("updateVoteMode",json,json.length,json.length == 1);
  if (json.length == 1) {
    // console.log("updateVoteMode1",json[0]["VoteMode"]);
    setFio(json[0]["LastName"] + " " + json[0]["FirstName"]  + " " + json[0]["SecondName"] );
    setfulltime(json[0]["TotalTime"]);
    setleftTime(FormatMinutesLeft(json[0]["LeftTime"]));
    setCaption(json[0]["Theme"]);
    setSpeechType(json[0]["SpeechTypeName"]);
  }
}

useEffect(() => {
  let url = server.api + "delegates.php?statem=1"
  console.log("updateVoteMode", url);
  getDataJson(url, updateVoteMode)
}, []);

useEffect(() => {
  const timer = setInterval(() => {
    // setSeconds(seconds => seconds + 1);
    let url = server.api + "delegates.php?statem=1"
    console.log("updateVoteMode", url);
    getDataJson(url, updateVoteMode)
  }, 500);
  
  // очистка интервала
  return () => clearInterval(timer);
});


  return (
  <Grid container className={styles.mainContainer} item>
    <Grid item className={styles.name}>
      Государственный Совет Удмуртской Республики
    </Grid>
    {/* <div className={styles.question} >{caption.replace(/"/g, '')}</div> */}
    <Grid container className={styles.centerContainer}>
    {(speechtype == "Statement_Speech") ? <><Grid container className={styles.headContainer}>
        <Grid item className={styles.header} >Выступление с места</Grid>
        <Grid item className={styles.fio}> {fio}</Grid>
      </Grid>
        </> : <> <Grid container className={styles.headContainer}>
        <Grid item className={styles.header} >Идет выступление</Grid>
        <Grid item className={styles.fio}> {fio}</Grid>
      </Grid>
      {lefttime == "0" ? <>
      <Grid item container className={styles.PresentationProcessSmall}>
        <Grid item className={styles.presentation} >
          <div>Время на выступление</div>
        </Grid>
        <Grid item className={styles.PresentationResStyle}>
        {(fulltime == "") ? <></> : <div>{fulltime} мин</div>}
        </Grid>
      </Grid>
      <div className={styles.timeOver}>Время истекло</div> 
      </>
      :
      <Grid item container className={styles.PresentationProcess}>
          <Grid item className={styles.presentation} >
            <div>Время на выступление</div>
            <div>Осталось времени</div>
          </Grid>
          <Grid item className={styles.PresentationResStyle}>
          {(fulltime == "") ? <></> : <div>{fulltime} мин</div>}
          {(lefttime == "") ? <></> : <div>{lefttime} мин</div>}
          </Grid>
        </Grid>
        }
      </>
      }
    </Grid>
    <div className={styles.bottomRowContainer}>
      <Grid className={styles.bottomRow}>
        <ScreenDateLarge />
        <ScreenTimeLarge />
      </Grid>
    </div>
  </Grid>

  );
}
