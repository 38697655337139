import React, { useEffect, useContext } from "react";
import {
  Navigate,
  useRoutes,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ContextInfoProvider, {
  ContextInfo,
  useContextInfo,
} from "../ContextInfo";
import {getDataJson} from "../services/script";
import { server } from "../settings";
import { Grid } from "@mui/material";
import styles from "./ScreenVotingProcessLarge.module.css";
import ScreenDateLarge from '../components/ScreenDateLarge';
import ScreenTimeLarge from '../components/ScreenTimeLarge';
import { FormatSeconds } from "../common/getDataJson";

export default function ScreenRegProcessLarge(props) {

  const { colors, setColors } = useContextInfo();
  const [lefttime, setleftTime] = React.useState(0);
  const [current, setCurrent] = React.useState(' ');
  const [total, setTotal] = React.useState(' ');


  const {
    userOrderInfo,
    statusApp,
    regWindow,
    voting,
    votingStep,
    cardRegistered,
  } = useContextInfo();

  // function formatSeconds(seconds) {
  //     var hours   = "" + Math.floor(seconds / 3600);
  //     var minutes = "" + Math.floor((seconds - (hours * 3600)) / 60);
  //     var formattedTime = '';

  //     if (hours > 0) {
  //         formattedTime += hours + "h ";
  //     }

  //     if (minutes > 0) {
  //         formattedTime += minutes + ":";
  //     }
     

  //     formattedTime += (seconds - (hours * 3600) - (minutes * 60)) + "";

  //     return formattedTime;
  // }
 
  // function formatSeconds(secs) {
  //   var minutes = "" + Math.floor((secs / 60) % 60);
  //   var seconds = "" + Math.floor(secs % 60);
  //   var formattedTime = '';

  //    formattedTime = (minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0')); 
  // return formattedTime;
  // }
  
  function updateRegMode(json) {
    if (json.length == 1) {
      console.log("updateVoteMode",json);
      setleftTime(FormatSeconds(json[0]["regtime_left"]));
      setCurrent(json[0]["reg_current"] == null ? 0 : json[0]["reg_current"]);
      setTotal(json[0]["reg_total"]);

    }
  }

  useEffect(() => {
    let url = server.api + "delegates.php?state=1"
    console.log("updateRegMode", url);
    getDataJson(url, updateRegMode)
  }, []);
  
  useEffect(() => {
    const timer = setInterval(() => {
      // setSeconds(seconds => seconds + 1);
      let url = server.api + "delegates.php?state=1"
      console.log("updateRegMode", url);
      getDataJson(url, updateRegMode)
    }, 500);
    
    // очистка интервала
    return () => clearInterval(timer);
  });

  return (
    <Grid container className={styles.mainContainer}>
    <Grid item className={styles.name}>Государственный Совет Удмуртской Республики</Grid>
    <Grid container className={styles.centerContainer}>
    <Grid item className={styles.header}>Идет регистрация</Grid>
    {lefttime != 0 &&
      <Grid item contsiner className={styles.votingProcessContainer}>
        <Grid item className={styles.voting} >
          <div>Осталось времени</div>
          <div>Зарегистрировались</div>
          <div>Присутствуют</div>
        </Grid>
        <Grid item className={styles.votingResStyle}>
          <div>{lefttime} </div>
          <div>{current} </div>
          <div>{total} </div>
        </Grid>
      </Grid>
    }
    </Grid>
    <div className={styles.bottomRowContainer}>
      <Grid container className={styles.bottomRow}>
        <ScreenDateLarge />
        <ScreenTimeLarge />
      </Grid>
    </div>
  </Grid>
  );
}
