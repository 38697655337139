import React, {useEffect}  from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Context } from '../Context';
import Voting from './Voting';
import VotingPrepare from './VotingPrepare';
import VotingRes from './VotingRes';

import ScreenVotingRes from './ScreenVotingRes';
import CountVoices from './CountVoices';

const style = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    // transform: 'translate(-50%, -50%)',
    width: "100%",
    height: "100vh",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 0,
    m:0,
  };

  const buttonCSS = {
    height:"12vw",
    borderRadius:"2vw", 
    border: "3px solid",
    fontSize:"5vh",
    boxShadow: "2px 2px 10px 5px rgb(0 0 0 / 25%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
  }

  export default function VotingModal(props) {
    const [open, setOpen] = React.useState(props.show);
    const [title, setTitle] = React.useState("Процедура регистрации");
    // let {show} = props;
    const [showlabel, setShow] = React.useState(true);
    const handleOpen = () => setOpen(false);
    const handleClose = () => setOpen(false);
    const sendCommand = () => {
      Context.confServer.sendCmd("R");
      setTitle('Вы зарегистрированы');
      setShow(false);
    }
   
    // useEffect(() => { //если диалоговое окно закрывается, в дальнейшем регистрация будет идти заново
     
    //   console.log("----------------",props.show);
    //   if (props.show) {
    //     setTitle("Процедура регистрации");
    //     setShow(true);
    //     return 
    //   } else return
    // }, [props.show]
    // );
    console.log("props.votingPrep",props);
    return (
     
        <Modal
         hideBackdrop
          open={props.show}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
           <Box sx={style}  align="center"    >
             {/* <Voting votingPrep={props.votingPrep} /> */}
             {
                {
                  'prepare': <VotingPrepare />,
                  'vote': <Voting />,
                  // 'result': <VotingRes />,
                  'off': <CountVoices />,
                  'result': <VotingRes />
                }[props.voteWindow]
              }
             
           </Box>
        
        </Modal>
 
    );
  }
